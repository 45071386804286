<template>
	<transition name="pane-module">
		<div class="panemodule" style="border-bottom: 0 !important;">
			<ul class="surface-card p-2 m-0 list-none flex overflow-x-auto select-none" style="border-bottom: 1px solid #CCC;">
				<li v-if="ModuleNeuf()" class="pr-3" @click="$store.dispatch('set_view', { view: 'neuf' });">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" :class="{'bg-primary hover:bg-primary': (app.view=='neuf' || app.view==''), 'text-700': !(app.view=='neuf' || app.view=='')}">
						<i class="pi pi-building mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('neuf') }}&nbsp;&nbsp;&nbsp;<span v-if="app.nbprogselect" class="badge" :class="getSeverity()">{{ app.nbprogselect }}</span></span>
					</a>
				</li>
				<li v-if="ModuleNeuf()" class="flex align-items-center">
					<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
				</li>
				<li v-if="ModuleRS() && app.module_rs" class="px-3" @click="app.module_rs ? $store.dispatch('set_view', { view: 'rss' }) : $store.dispatch('add_notificationone', { status: 'info', message: '', message2: messageabo, url: 'https://adequation.fr/page-dupliquee' })">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': (app.view=='rss'), 'text-700': !(app.view=='rss')}">
						<i class="pi pi-users mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{$t('Résidence services')}}&nbsp;&nbsp;&nbsp;<span v-if="app.nbresidenceselect" class="badge" :class="getSeverityRS()">{{ app.nbresidenceselect }}</span></span>
					</a>
				</li>
				<li v-else class="px-3" @click="clickrs();" @mouseover="showMessageRS = true" @mouseout="showMessageRS = false">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': (app.view=='rss'), 'text-700': !(app.view=='rss')}">
						<i class="pi pi-users mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{$t('Résidence services')}}&nbsp;&nbsp;&nbsp;<span v-if="app.nbresidenceselect" class="badge" :class="getSeverityRS()">{{ app.nbresidenceselect }}</span></span>
					</a>
				</li>
				<li v-if="ModuleNeuf()" class="flex align-items-center">
					<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
				</li>
				<li v-if="app.efocus_type=='A' && app.mrk.lat && app.module_actif && app.ModuleAdresse && app.module_cityscan" class="px-3" @click="$store.dispatch('set_view', { view: 'ancien' })">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': getViewAncien(), 'text-700': !getViewAncien()}">
						<i class="pi pi-key mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('ancien') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>
				<li v-else-if="app.efocus_type=='A'" class="px-3" @click="clickancien();" @mouseover="showMessage0 = true" @mouseout="showMessage0 = false">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': getViewAncien(), 'text-700': !getViewAncien()}">
						<i class="pi pi-key mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('ancien') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>
				<li v-if="app.efocus_type!='efocus-parcours-personnalises-senior' && app.efocus_type!='efocus-parcours-personnalises-tous'" class="flex align-items-center" @click="$store.dispatch('set_view', { view: 'locatif' })">
					<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
				</li>
				<li class="px-3" v-if="app.efocus_type=='A' && app.module_actif && app.module_cityscan" @click="$store.dispatch('set_view', { view: 'locatif' })">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': (app.view=='locatif' || app.view=='ancien-locatif' || app.view=='rpls'), 'text-700': !(app.view=='locatif' || app.view=='ancien-locatif' || app.view=='rpls')}">
						<i class="pi pi-user mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('locatif') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>
				<li class="px-3" v-else-if="app.efocus_type=='A'"  @click="clickancien();" @mouseover="showMessage1 = true" @mouseout="showMessage1 = false">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': (app.view=='locatif' || app.view=='ancien-locatif' || app.view=='rpls'), 'text-700': !(app.view=='locatif' || app.view=='ancien-locatif' || app.view=='rpls')}">
						<i class="pi pi-user mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('locatif') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>
				<li v-if="app.efocus_type!='efocus-parcours-personnalises-senior' && app.efocus_type!='efocus-parcours-personnalises-tous'" class="flex align-items-center" @click="app.mrk.lat ? $store.dispatch('set_view', { view: 'adresse' }) : $store.dispatch('add_notification', { status: 'error', message: messagewarning }) ">
					<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
				</li>
				
				
				<li class="px-3" v-if="app.user.adequation_po&&false" @click="$store.dispatch('set_view', { view: 'carto-locatif' })">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"  :class="{'bg-primary hover:bg-primary': (app.view=='carto-locatif' || app.view=='annonces-locatif' || app.view=='rpls'), 'text-700': !(app.view=='carto-locatif' || app.view=='annonces-locatif' || app.view=='rpls')}">
						<i class="pi pi-user mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('KPI locatif') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>
				<li v-if="app.user.adequation_po&&false" class="flex align-items-center" @click="app.mrk.lat ? $store.dispatch('set_view', { view: 'adresse' }) : $store.dispatch('add_notification', { status: 'error', message: messagewarning }) ">
					<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
				</li>


				<li class="px-3" v-if ="app.efocus_type=='A' && app.mrk.lat && app.module_actif && app.ModuleAdresse && app.module_cityscan" @click="app.mrk.lat ? $store.dispatch('set_view', { view: 'adresse' }) : $store.dispatch('add_notification', { status: 'error', message: messagewarning }) ">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150v"  :class="{'bg-primary hover:bg-primary': getViewAdr(), 'text-700': !getViewAdr()}">
						<i class="pi pi-map-marker mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('qualite_de_ladresse') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>
				<li class="px-3" v-else-if="app.efocus_type=='A'" @click="clickancien();" @mouseover="showMessage2 = true" @mouseout="showMessage2 = false">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center surface-100 border-round transition-colors transition-duration-150v"  :class="{'bg-primary hover:bg-primary': getViewAdr(), 'text-700': !getViewAdr()}">
						<i class="pi pi-map-marker mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('qualite_de_ladresse') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>

				<li class="flex align-items-center" v-if="app.module_foncier">
					<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
				</li>
				<li class="px-3" @click="$store.dispatch('set_view', { view: 'gisements' })"  v-if="app.module_foncier">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150v"  :class="{'bg-primary hover:bg-primary': app.view=='gisements', 'text-700': app.view!='gisements'}">
						<i class="pi pi-map-marker mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('Gisements fonciers') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>

				<li class="flex align-items-center" v-if="app.user.email=='s.dupont@adequation-france.com'">
					<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
				</li>
				<li class="px-3" @click="$store.dispatch('set_view', { view: 'fil' })"  v-if="app.user.email=='s.dupont@adequation-france.com'">
					<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150v"  :class="{'bg-primary hover:bg-primary': app.view=='fil', 'text-700': app.view!='gisements'}">
						<i class="pi pi-map-marker mr-2"></i>
						<span class="font-medium">&nbsp;&nbsp;&nbsp;{{ $t('FIL') }}&nbsp;&nbsp;&nbsp;</span>
					</a>
				</li>
			</ul>
			<div v-if="app.efocus_type=='A'" class="toreport">
				<Button icon="pi pi-chart-bar" label="Synthèse" class="p-button-rounded p-button-success" v-if="app.loading" type="submit" disabled="disabled" />
				<Button icon="pi pi-chart-bar" label="Synthèse" class="p-button-rounded p-button-success" v-else-if="(app.nbprogselect>2) || (app.view == 'rss')" type="submit" @click="$store.dispatch('set_help', {aide:false}); $store.dispatch('set_help_prog', {aide:false}); $store.dispatch('set_help_live', {aide:false});report()" />
				<Button icon="pi pi-chart-bar" label="Synthèse" class="p-button-rounded p-button-success" v-else disabled="disabled" />
			</div>
			<div v-if="app.efocus_type=='B'" class="toreport">
				<Button icon="pi pi-chart-bar" label="Positionnement" class="p-button-rounded p-button-success" v-if="(app.nbprogselect>2) || (app.nbprogselect==0 && app.nbresidenceselect > 0)" type="submit" @click="$store.dispatch('set_help', {aide:false}); $store.dispatch('set_help_prog', {aide:false}); $store.dispatch('set_help_live', {aide:false});report_B()"/>
				<Button icon="pi pi-chart-bar" label="Positionnement" class="p-button-rounded p-button-success" v-else disabled="disabled" />
			</div>
			<div v-if="app.efocus_type=='efocus-parcours-personnalises-senior' || app.efocus_type=='efocus-parcours-personnalises-tous'" class="toreport">
				<Button icon="pi pi-chart-bar" label="Synthèse" class="p-button-rounded p-button-success" v-if="app.loading" type="submit" disabled="disabled" />
				<Button icon="pi pi-chart-bar" label="Synthèse" class="p-button-rounded p-button-success" v-else type="submit" @click="$store.dispatch('set_help', {aide:false}); $store.dispatch('set_help_prog', {aide:false}); $store.dispatch('set_help_live', {aide:false});report_E()" />
			</div>
			<div v-if="showMessageRS&&!app.module_rs" style="z-index:999; position:absolute; left: 150px;top:70px; border: 1px solid #6c21f9;  border-radius: 5px;display: block; background-color: white; width: auto; height: auto;padding: 10px;">
				<div style="color:#666;">
					<p><b>{{ $t('module_residence_services') }}</b></p><p>&nbsp;</p>
					<p>{{ $t('vous_ne_disposez_pas_actuellement_dacces_a_ce_module._si_vous_souhaitez_lelargir,') }}</p>
					<p>{{ $t('veuillez_contacter_le_responsable_en_charge_de_labonnement_de_votre_societe') }}</p>
				</div>
			</div>
			<div v-if="showMessage0&&app.module_cityscan&&!app.ModuleAdresse" style="z-index:999; position:absolute; left: 307px;top:70px; border: 1px solid #6c21f9;  border-radius: 5px;display: block; background-color: white; width: auto; height: auto;padding: 10px;">
				<div style="color:#666;">
					<p><b>{{ $t('module_de_lancien') }}</b></p><p>&nbsp;</p>
					<p>{{ $t('ce_module_nest_accessible_qua_partir_dune_adresse_specifique._pour_y_acceder,') }}</p>
					<p>{{$t('veuillez préciser votre recherche en saisissant un numéro et une rue.')}}</p>
				</div>
			</div>
			<div v-if="showMessage0&&app.module_cityscan&&!app.module_actif" style="z-index:999; position:absolute; left: 307px;top:70px; border: 1px solid #6c21f9;  border-radius: 5px;display: block; background-color: white; width: auto; height: auto;padding: 10px;">
				<div style="color:#666;">
					<p><b>{{ $t('module_de_lancien') }}</b></p><p>&nbsp;</p>
					<p>{{ $t('vous_ne_disposez_pas_actuellement_dacces_a_ce_territoire_pour_ce_module._si_vous_souhaitez_lelargir,') }}</p>
					<p>{{ $t('veuillez_contacter_le_responsable_en_charge_de_labonnement_de_votre_societe') }}</p>
				</div>
			</div>
			<div v-if="showMessage1&&app.module_cityscan&&!app.module_actif" style="z-index:999; position:absolute; left: 427px;top:70px; border: 1px solid #6c21f9;  border-radius: 5px;display: block; background-color: white; width: auto; height: auto;padding: 10px;">
				<div style="color:#666;">
					<p><b>{{$t('Module du locatif')}}</b></p><p>&nbsp;</p>
					<p>{{ $t('vous_ne_disposez_pas_actuellement_dacces_a_ce_territoire_pour_ce_module._si_vous_souhaitez_lelargir,') }}</p>
					<p>{{ $t('veuillez_contacter_le_responsable_en_charge_de_labonnement_de_votre_societe') }}</p>
				</div>
			</div>
			<div v-if="showMessage2&&app.module_cityscan&&!app.ModuleAdresse" style="z-index:999; position:absolute; left: 547px;top:70px; border: 1px solid #6c21f9;  border-radius: 5px;display: block; background-color: white; width: auto; height: auto;padding: 10px;">
				<div style="color:#666;">
					<p><b>{{ $t('module_qualite_de_ladresse') }}</b></p><p>&nbsp;</p>
					<p>{{ $t('ce_module_nest_accessible_qua_partir_dune_adresse_specifique._pour_y_acceder,') }}</p>
					<p>{{$t('veuillez préciser votre recherche en saisissant un numéro et une rue.')}}</p>
				</div>
			</div>
			<div v-if="showMessage2&&app.module_cityscan&&!app.module_actif" style="z-index:999; position:absolute; left: 547px;top:70px; border: 1px solid #6c21f9;  border-radius: 5px;display: block; background-color: white; width: auto; height: auto;padding: 10px;">
				<div style="color:#666;">
					<p><b>{{ $t('module_qualite_de_ladresse') }}</b></p><p>&nbsp;</p>
					<p>{{ $t('vous_ne_disposez_pas_actuellement_dacces_a_ce_territoire_pour_ce_module._si_vous_souhaitez_lelargir,') }}</p>
					<p>{{ $t('veuillez_contacter_le_responsable_en_charge_de_labonnement_de_votre_societe') }}</p>
				</div>
			</div>
			
			<Dialog header="Module additionnel " :visible.sync="DialogMessage0" :containerStyle="{width: '50vw'}">
				<p class="m-0">{{ $t('pas_abonnement_necessaire') }}</p>
				<template #footer>
					<Button :label="$t('Fermer')" icon="pi pi-times" @click="closeBasicAncien" class="p-button-text"/>
					<a target="_blank" href="https://forms.news.adequation-france.com/5a3b976fb85b5348455de322/btH1skRJRUyZnjrbL-Z1kA/y69MBKoZSfiA114lXRQoJQ/form.html"><Button :label="$t('Découvrir')" icon="pi pi-check" @click="closeBasicAncien" autofocus /></a>
				</template>
			</Dialog>
			
			<Dialog header="Module additionnel " :visible.sync="DialogMessage1" :containerStyle="{width: '50vw'}">
				<p class="m-0">{{ $t('module_a_souscription') }}</p>
				<template #footer>
					<Button :label="$t('Fermer')" icon="pi pi-times" @click="closeBasicAncien" class="p-button-text"/>
					<a target="_blank" href="https://forms.news.adequation-france.com/5a3b976fb85b5348455de322/btH1skRJRUyZnjrbL-Z1kA/y69MBKoZSfiA114lXRQoJQ/form.html"><Button :label="$t('Découvrir')" icon="pi pi-check" @click="closeBasicAncien" autofocus /></a>
				</template>
			</Dialog>
			
			<Dialog header="Module additionnel " :visible.sync="DialogMessage2" :containerStyle="{width: '50vw'}">
				<p class="m-0">{{ $t('vous_ne_disposez_pas_actuellement_dacces_a_ce_territoire_pour_ce_module._si_vous_souhaitez_lelargir,') }} {{$t('veuillez contacter le responsable en charge de l\'abonnement de votre société')}}</p>
				<template #footer>
					<Button :label="$t('Fermer')" icon="pi pi-times" @click="closeBasicAncien" class="p-button-text"/>
					<a target="_blank" href="https://forms.news.adequation-france.com/5a3b976fb85b5348455de322/btH1skRJRUyZnjrbL-Z1kA/y69MBKoZSfiA114lXRQoJQ/form.html"><Button :label="$t('Découvrir')" icon="pi pi-check" @click="closeBasicAncien" autofocus /></a>
				</template>
			</Dialog>
			
			<Dialog header="Module additionnel " :visible.sync="DialogMessage3" :containerStyle="{width: '50vw'}">
				<p class="m-0">{{ $t('ce_module_nest_accessible_qua_partir_dune_adresse_specifique._pour_y_acceder,') }} {{$t('veuillez préciser votre recherche en saisissant un numéro et une rue.')}}</p>
				<template #footer>
					<Button :label="$t('Fermer')" icon="pi pi-times" @click="closeBasicAncien" class="p-button-text"/>
				</template>
			</Dialog>
			
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export default {
	name: 'PaneModule',
	data: function() {
		return {
			messages:[],
			showMessageRS: false,
			showMessage0: false,
			showMessage1: false,
			showMessage2: false,
			DialogMessage0: false,
			DialogMessage1: false,
			DialogMessage2: false,
			DialogMessage3: false,
			messagewarning: "Ce module est actif uniquement si une adresse est saisie",
			messageabo: "Module disponible à la souscription. Cliquez ici pour obtenir plus d'informations"
		}
	},
	computed: Object.assign(mapGetters([ 'app', 'map', 'programs_filter' ]), {

	}),
	watch: {

	},
	methods: {
		ModuleNeuf: function() {
			let v = ['efocus-parcours-personnalises-tous', 'efocus-parcours-personnalises-senior', 'efocus-parcours-personnalises-etudiant', 'efocus-parcours-personnalises-tourisme', 'efocus-parcours-personnalises-tout-age']
			return !v.includes(this.app.efocus_type)
        },
		ModuleRS: function() {
			let v = ['A', 'efocus-parcours-personnalises-tous', 'efocus-parcours-personnalises-senior', 'efocus-parcours-personnalises-etudiant', 'efocus-parcours-personnalises-tourisme', 'efocus-parcours-personnalises-tout-age']
			return v.includes(this.app.efocus_type)
        },
		ModuleRPLS: function() {
			if (this.app.user) return this.app.user.adequation
			return false
        },
        openBasicAncien() {
            this.DialogMessage0 = true;
        },
        closeBasicAncien() {
            this.DialogMessage0 = false;
			this.DialogMessage1 = false;
			this.DialogMessage2 = false;
			this.DialogMessage3 = false;
        },
		getSeverity:function() {
			if (this.app.nbprogselect < 3) return 'badge-danger'
			else return 'badge-success'
		},
		getSeverityRS:function() {
			return 'badge-success'
		},
		getSeverityRPLS:function() {
			return 'badge-success'
		},
		clickrs: function() {
			this.DialogMessage1 = true;
		},
		clickrpls: function() {
			this.DialogMessage1 = true;
		},
		clickancien: function() {
			if (this.app.efocus_type!='A') {
				this.DialogMessage0 = true;
			} else {
				if (!this.app.module_cityscan) {
					this.DialogMessage1 = true;
				} else {
					if (!this.app.module_actif) {
						this.DialogMessage2 = true;
					} else {
						if (this.app.mrk.lat) {
							this.DialogMessage3 = true;
						}
					}
				}
			}
		},

		add_notification: function() {
			let message = ''
			if (!this.app.module_cityscan) {
				message = 'Vous n\'êtes pas abonné à ce module'
			} else {
				if (!this.app.mrk.lat || !this.app.module_actif || !this.app.ModuleAdresse) {
					message = 'La localisation de l\'adresse ne permet pas l\'activation de ce module'
				} else {
					message = 'Impossible d\'activer le module'
				}
			}
			this.$store.dispatch('add_notification', { status: 'error', message: message })
		},
		getViewAdr: function() {
			let t = ['adresse', 'vie', 'transport', 'transport-bus', 'transport-tram', 'transport-metro','transport-route-rapide',
					'transport-parking', 'transport-gare', 'transport-aeroport', 'transport-port', 'transport-velo', 'transport-auto',
					'transport-borne', 'education', 'education-creche', 'education-maternelle', 'education-elementaire', 'education-college',
					'education-lycee', 'education-lycee-pro', 'education-universite', 'education-superieur', 'education-formation',
					'commerces', 'commerce-alimentation', 'commerce-bouche', 'commerce-utilitaire', 'commerce-shopping', 'commerce-soin',
					'commerce-logement', 'commerce-vehicule', 'loisirs', 'loisir-bibliotheque', 'loisir-theatre', 'loisir-musee', 'loisir-cinema',
					'loisir-bar', 'loisir-restaurant', 'loisir-discotheque', 'loisir-jardin', 'loisir-sport-collectif', 'loisir-sport-individuel',
					'loisir-hotel', 'loisir-hebergement', 'services', 'service-agees', 'service-commissariat', 'service-dechet', 'service-gendarmerie',
					'service-hopital', 'service-laboratoire', 'service-medecin', 'service-pharmacie', 'service-pompier', 'service-poste', 
					'service-recyclage', 'service-specialiste', 'numerique']
			return (t.indexOf(this.app.view)>-1)
		},
		getViewAncien: function() {
			let t = ['ancien', 'ancien-mutations', 'ancien-conjoncture', 'ancien-offre']
			return (t.indexOf(this.app.view)>-1)
		},
/*
		report: function() {
			let t = []
			let p = []
			let tr = []
			let pr = []
			let secteurs = []
			let secteurs_lib = []
			let i
				for (i in this.app.programs_filter) {
					if (document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville)) {
						if (document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville).className == 'checkbox checked') {
							if (this.app.programs_filter[i].pc) {
								p.push({code : this.app.programs_filter[i].id})
							} else {
								t.push({code : this.app.programs_filter[i].id})
								if (!secteurs.includes(this.app.programs_filter[i].secteur)) {
									secteurs.push(this.app.programs_filter[i].secteur);
								}
								if (!secteurs_lib.includes(this.app.programs_filter[i].secteur_nom)) {
									secteurs_lib.push(this.app.programs_filter[i].secteur_nom);
								}
							}
						}
					}
				}
				for (i in this.app.rss_filter) {
					if (document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville)) {
						if (document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville).className == 'checkbox checked') {
							if (this.app.rss_filter[i].pc) {
								pr.push({code : this.app.rss_filter[i].id})
							} else {
								tr.push({code : this.app.rss_filter[i].id})
							}
						}
					}
				}
				this.$store.dispatch('set_secteur', {secteurs: secteurs, secteurs_lib: secteurs_lib})
				this.$store.dispatch('set_modules_adresse', {
					adresse_services: document.getElementById('adresse_services').checked,
					adresse_numerique: document.getElementById('adresse_numerique').checked,
					adresse_loisirs: document.getElementById('adresse_loisirs').checked,
					adresse_vie: document.getElementById('adresse_vie').checked,
					adresse_commerces: document.getElementById('adresse_commerces').checked,
					adresse_education: document.getElementById('adresse_education').checked,
					adresse_transport: document.getElementById('adresse_transport').checked,

					adresse_transport_bus: document.getElementById('adresse_transport_bus').checked,
					adresse_transport_metro: document.getElementById('adresse_transport_metro').checked,
					adresse_transport_tram: document.getElementById('adresse_transport_tram').checked,
					adresse_transport_route_rapide: document.getElementById('adresse_transport_route_rapide').checked,
					adresse_transport_parking: document.getElementById('adresse_transport_parking').checked,
					adresse_transport_gare: document.getElementById('adresse_transport_gare').checked,
					adresse_transport_aeroport: document.getElementById('adresse_transport_aeroport').checked,
					adresse_transport_port: document.getElementById('adresse_transport_port').checked,
					adresse_transport_velo: document.getElementById('adresse_transport_velo').checked,
					adresse_transport_auto: document.getElementById('adresse_transport_auto').checked,
					adresse_transport_borne: document.getElementById('adresse_transport_borne').checked,

					adresse_ecole_creche: document.getElementById('adresse_ecole_creche').checked,
					adresse_ecole_maternelle: document.getElementById('adresse_ecole_maternelle').checked,
					adresse_ecole_elementaire: document.getElementById('adresse_ecole_elementaire').checked,
					adresse_ecole_college: document.getElementById('adresse_ecole_college').checked,
					adresse_ecole_lycee: document.getElementById('adresse_ecole_lycee').checked,
					adresse_ecole_lycee_pro: document.getElementById('adresse_ecole_lycee_pro').checked,
					adresse_ecole_universite: document.getElementById('adresse_ecole_universite').checked,
					adresse_ecole_superieur: document.getElementById('adresse_ecole_superieur').checked,
					adresse_ecole_formation: document.getElementById('adresse_ecole_formation').checked,
					
					adresse_commerce_alimentation: document.getElementById('adresse_commerce_alimentation').checked,
					adresse_commerce_bouche: document.getElementById('adresse_commerce_bouche').checked,
					adresse_commerce_utilitaire: document.getElementById('adresse_commerce_utilitaire').checked,
					adresse_commerce_shopping: document.getElementById('adresse_commerce_shopping').checked,
					adresse_commerce_soin: document.getElementById('adresse_commerce_soin').checked,
					adresse_commerce_logement: document.getElementById('adresse_commerce_logement').checked,
					adresse_commerce_vehicule: document.getElementById('adresse_commerce_vehicule').checked,

					adresse_sonore: document.getElementById('adresse_sonore').checked,
					adresse_olfactive: document.getElementById('adresse_olfactive').checked,
					adresse_saturation_population: document.getElementById('adresse_saturation_population').checked,
					adresse_saturation_vehicule: document.getElementById('adresse_saturation_vehicule').checked,
					adresse_air: document.getElementById('adresse_air').checked,
					adresse_electromagnetique: document.getElementById('adresse_electromagnetique').checked,
					adresse_radon: document.getElementById('adresse_radon').checked,

					adresse_haut_debit: document.getElementById('adresse_haut_debit').checked,
					adresse_fibre: document.getElementById('adresse_fibre').checked,
					adresse_mobile_4G: document.getElementById('adresse_mobile_4G').checked,
					adresse_mobile_3G: document.getElementById('adresse_mobile_3G').checked,

					adresse_loisir_bibliotheque: document.getElementById('adresse_loisir_bibliotheque').checked,
					adresse_loisir_theatre: document.getElementById('adresse_loisir_theatre').checked,
					adresse_loisir_musee: document.getElementById('adresse_loisir_musee').checked,
					adresse_loisir_cinema: document.getElementById('adresse_loisir_cinema').checked,
					adresse_loisir_bar: document.getElementById('adresse_loisir_bar').checked,
					adresse_loisir_restaurant: document.getElementById('adresse_loisir_restaurant').checked,
					adresse_loisir_discotheque: document.getElementById('adresse_loisir_discotheque').checked,
					adresse_loisir_jardin: document.getElementById('adresse_loisir_jardin').checked,
					adresse_loisir_sport_collectif: document.getElementById('adresse_loisir_sport_collectif').checked,
					adresse_loisir_sport_individuel: document.getElementById('adresse_loisir_sport_individuel').checked,
					adresse_loisir_hotel: document.getElementById('adresse_loisir_hotel').checked,
					adresse_loisir_hebergement: document.getElementById('adresse_loisir_hebergement').checked,

					adresse_service_commissariat: document.getElementById('adresse_service_commissariat').checked,
					adresse_service_gendarmerie: document.getElementById('adresse_service_gendarmerie').checked,
					adresse_service_pompier: document.getElementById('adresse_service_pompier').checked,
					adresse_service_hopital: document.getElementById('adresse_service_hopital').checked,
					adresse_service_laboratoire: document.getElementById('adresse_service_laboratoire').checked,
					adresse_service_agees: document.getElementById('adresse_service_agees').checked,
					adresse_service_pharmacie: document.getElementById('adresse_service_pharmacie').checked,
					adresse_service_medecin: document.getElementById('adresse_service_medecin').checked,
					adresse_service_specialiste: document.getElementById('adresse_service_specialiste').checked,
					adresse_service_poste: document.getElementById('adresse_service_poste').checked,
					adresse_service_recyclage: document.getElementById('adresse_service_recyclage').checked,
					adresse_service_dechet: document.getElementById('adresse_service_dechet').checked,
				})
				
				this.$store.dispatch('report_by_id', { programs: t, pc: p, rss: tr, pcr: pr, view: this.app.view })

		},
*/
/**/
		report: function() {
			let t = [], p = [], tr = [], pr = [], secteurs = [], secteurs_lib = [];
			
			const addFilters = (filters, tArray, pArray) => {
				filters.forEach(filter => {
					const elem = document.getElementById(`${filter.id}-${filter.ville}`);
					if (elem && elem.className === 'checkbox checked') {
						if (filter.pc) {
							pArray.push({ code: filter.id });
						} else {
							if (filter && filter.id && filter.secteur) {
								tArray.push({ code: filter.id });
								if (!secteurs.includes(filter.secteur)) {
									secteurs.push(filter.secteur);
								}
								if (!secteurs_lib.includes(filter.secteur_nom)) {
									secteurs_lib.push(filter.secteur_nom);
								}
							}
						}
					}
				});
			};

			addFilters(this.app.programs_filter, t, p);
//			addFilters(this.app.rss_filter, tr, pr);

			for (let i in this.app.rss_filter) {
					if (document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville)) {
						if (document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville).className == 'checkbox checked') {
							if (this.app.rss_filter[i].pc) {
								pr.push({code : this.app.rss_filter[i].id})
							} else {
								tr.push({code : this.app.rss_filter[i].id})
							}
						}
					}
				}

			const getElementChecked = id => document.getElementById(id).checked;

			const addressModules = [
				'services', 'numerique', 'loisirs', 'vie', 'commerces', 'education', 'transport',
				'transport_bus', 'transport_metro', 'transport_tram', 'transport_route_rapide', 'transport_parking', 'transport_gare', 'transport_aeroport', 'transport_port', 'transport_velo', 'transport_auto', 'transport_borne',
				'ecole_creche', 'ecole_maternelle', 'ecole_elementaire', 'ecole_college', 'ecole_lycee', 'ecole_lycee_pro', 'ecole_universite', 'ecole_superieur', 'ecole_formation',
				'commerce_alimentation', 'commerce_bouche', 'commerce_utilitaire', 'commerce_shopping', 'commerce_soin', 'commerce_logement', 'commerce_vehicule',
				'sonore', 'olfactive', 'saturation_population', 'saturation_vehicule', 'air', 'electromagnetique', 'radon',
				'haut_debit', 'fibre', 'mobile_4G', 'mobile_3G',
				'loisir_bibliotheque', 'loisir_theatre', 'loisir_musee', 'loisir_cinema', 'loisir_bar', 'loisir_restaurant', 'loisir_discotheque', 'loisir_jardin', 'loisir_sport_collectif', 'loisir_sport_individuel', 'loisir_hotel', 'loisir_hebergement',
				'service_commissariat', 'service_gendarmerie', 'service_pompier', 'service_hopital', 'service_laboratoire', 'service_agees', 'service_pharmacie', 'service_medecin', 'service_specialiste', 'service_poste', 'service_recyclage', 'service_dechet'
			];

			const addressModulesState = addressModules.reduce((acc, module) => {
				acc[`adresse_${module}`] = getElementChecked(`adresse_${module}`);
				return acc;
			}, {});

			this.$store.dispatch('set_secteur', { secteurs, secteurs_lib });
			this.$store.dispatch('set_modules_adresse', addressModulesState);
			this.$store.dispatch('report_by_id', { programs: t, pc: p, rss: tr, pcr: pr, view: this.app.view });
		},
/**/
		report_E: function() {
			let tr = []
			let pr = []
			let i
			for (i in this.app.rss_filter) {
				if (document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville)) {
					if (document.getElementById(this.app.rss_filter[i].id + "-" + this.app.rss_filter[i].ville).className == 'checkbox checked') {
						if (this.app.rss_filter[i].pc) {
							pr.push({code : this.app.rss_filter[i].id})
						} else {
							tr.push({code : this.app.rss_filter[i].id})
						}
					}
				}
			}
			this.$store.dispatch('report_E_by_id', { rss: tr, pcr: pr, view: this.app.view })
		},
		
/*
		report_B: function() {
			let t = []
			let secteurs = []
			let secteurs_lib = []
			for (let i in this.app.programs_filter) {
				if (document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville)) {
					if (document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville).className == 'checkbox checked') {
						if (!this.app.programs_filter[i].pc) {
							t.push({code : this.app.programs_filter[i].id})
							if (!secteurs.includes(this.app.programs_filter[i].secteur)) {
								secteurs.push(this.app.programs_filter[i].secteur);
							}
							if (!secteurs_lib.includes(this.app.programs_filter[i].secteur_nom)) {
								secteurs_lib.push(this.app.programs_filter[i].secteur_nom);
							}
						}
					}
				}
			}
			this.$store.dispatch('set_secteur', {secteurs: secteurs, secteurs_lib: secteurs_lib})
			this.$store.dispatch('set_modules_adresse', {
					adresse_services: false,
					adresse_numerique: false,
					adresse_loisirs: false,
					adresse_vie: false,
					adresse_commerces: false,
					adresse_education: true,
					adresse_transport: true,

					adresse_transport_bus: true,
					adresse_transport_metro: true,
					adresse_transport_tram: true,
					adresse_transport_route_rapide: true,
					adresse_transport_parking: true,
					adresse_transport_gare: true,
					adresse_transport_aeroport: true,
					adresse_transport_port: true,
					adresse_transport_velo: true,
					adresse_transport_auto: true,
					adresse_transport_borne: true,

					adresse_ecole_creche: true,
					adresse_ecole_maternelle: true,
					adresse_ecole_elementaire: true,
					adresse_ecole_college: true,
					adresse_ecole_lycee: true,
					adresse_ecole_lycee_pro: true,
					adresse_ecole_universite: true,
					adresse_ecole_superieur: true,
					adresse_ecole_formation: true,
					
					adresse_commerce_alimentation: false,
					adresse_commerce_bouche: false,
					adresse_commerce_utilitaire: false,
					adresse_commerce_shopping: false,
					adresse_commerce_soin: false,
					adresse_commerce_logement: false,
					adresse_commerce_vehicule: false,

					adresse_sonore: false,
					adresse_olfactive: false,
					adresse_saturation_population: false,
					adresse_saturation_vehicule: false,
					adresse_air: false,
					adresse_electromagnetique: false,
					adresse_radon: false,

					adresse_haut_debit: false,
					adresse_fibre: false,
					adresse_mobile_4G: false,
					adresse_mobile_3G: false,

					adresse_loisir_bibliotheque: false,
					adresse_loisir_theatre: false,
					adresse_loisir_musee: false,
					adresse_loisir_cinema: false,
					adresse_loisir_bar: false,
					adresse_loisir_restaurant: false,
					adresse_loisir_discotheque: false,
					adresse_loisir_jardin: false,
					adresse_loisir_sport_collectif: false,
					adresse_loisir_sport_individuel: false,
					adresse_loisir_hotel: false,
					adresse_loisir_hebergement: false,

					adresse_service_commissariat: false,
					adresse_service_gendarmerie: false,
					adresse_service_pompier: false,
					adresse_service_hopital: false,
					adresse_service_laboratoire: false,
					adresse_service_agees: false,
					adresse_service_pharmacie: false,
					adresse_service_medecin: false,
					adresse_service_specialiste: false,
					adresse_service_poste: false,
					adresse_service_recyclage: false,
					adresse_service_dechet: false,
				})
				
			this.$store.dispatch('report_B_by_id', { programs: t, view: this.app.view})
		},
*/
		report_B: function() {
			let t = [], secteurs = [], secteurs_lib = [];
			
			this.app.programs_filter.forEach(filter => {
				const elem = document.getElementById(`${filter.id}-${filter.ville}`);
				if (elem && elem.className === 'checkbox checked' && !filter.pc) {
					t.push({ code: filter.id });
					if (!secteurs.includes(filter.secteur)) secteurs.push(filter.secteur);
					if (!secteurs_lib.includes(filter.secteur_nom)) secteurs_lib.push(filter.secteur_nom);
				}
			});

			this.$store.dispatch('set_secteur', { secteurs, secteurs_lib });

			const modulesAdresse = {
				adresse_services: false, adresse_numerique: false, adresse_loisirs: false, adresse_vie: false,
				adresse_commerces: false, adresse_education: true, adresse_transport: true,
				adresse_transport_bus: true, adresse_transport_metro: true, adresse_transport_tram: true,
				adresse_transport_route_rapide: true, adresse_transport_parking: true, adresse_transport_gare: true,
				adresse_transport_aeroport: true, adresse_transport_port: true, adresse_transport_velo: true,
				adresse_transport_auto: true, adresse_transport_borne: true,
				adresse_ecole_creche: true, adresse_ecole_maternelle: true, adresse_ecole_elementaire: true,
				adresse_ecole_college: true, adresse_ecole_lycee: true, adresse_ecole_lycee_pro: true,
				adresse_ecole_universite: true, adresse_ecole_superieur: true, adresse_ecole_formation: true,
				adresse_commerce_alimentation: false, adresse_commerce_bouche: false, adresse_commerce_utilitaire: false,
				adresse_commerce_shopping: false, adresse_commerce_soin: false, adresse_commerce_logement: false,
				adresse_commerce_vehicule: false, adresse_sonore: false, adresse_olfactive: false,
				adresse_saturation_population: false, adresse_saturation_vehicule: false, adresse_air: false,
				adresse_electromagnetique: false, adresse_radon: false, adresse_haut_debit: false,
				adresse_fibre: false, adresse_mobile_4G: false, adresse_mobile_3G: false,
				adresse_loisir_bibliotheque: false, adresse_loisir_theatre: false, adresse_loisir_musee: false,
				adresse_loisir_cinema: false, adresse_loisir_bar: false, adresse_loisir_restaurant: false,
				adresse_loisir_discotheque: false, adresse_loisir_jardin: false, adresse_loisir_sport_collectif: false,
				adresse_loisir_sport_individuel: false, adresse_loisir_hotel: false, adresse_loisir_hebergement: false,
				adresse_service_commissariat: false, adresse_service_gendarmerie: false, adresse_service_pompier: false,
				adresse_service_hopital: false, adresse_service_laboratoire: false, adresse_service_agees: false,
				adresse_service_pharmacie: false, adresse_service_medecin: false, adresse_service_specialiste: false,
				adresse_service_poste: false, adresse_service_recyclage: false, adresse_service_dechet: false,
			};

			this.$store.dispatch('set_modules_adresse', modulesAdresse);
			this.$store.dispatch('report_B_by_id', { programs: t, view: this.app.view });
		}

	},
	// eslint-disable-next-line
	components: { Button, Dialog }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'
@import '../assets/css/cityscan.css'


.top-bar
	height 60px
	background white
	border-bottom 1px solid #cccccc
	width 100%
	color #333
	.top-bar-content
		width 100%
.content
	width 100%
	display flex
	align-items left


.legendecityscan
	position absolute
	bottom 10px
	left 10px

.cityscaninside
	width 180px
	height 60px
	vertical-align middle
	font-size: 18px
	font-style italic
	color primaryA400
.inactif
	background-color #efefef !important
	color #ccc
	border-right 4px solid #efefef !important
	&:hover
		border-right 4px solid #efefef !important

.panemodule
	position absolute
	top 60px
	width 100%
	height 60px
	border-bottom 1px solid #ccc !important

.module_ret
	width PaneLeftSize
	height 60px
	text-align center
	font-weight 600
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure
	.middle
		top 20px

.iconemodule
	width 50px
	font-size 1.875rem

.moduletop
	display block
	width 20%
	max-width 220px
	height 59px
	line-height 60px
	padding-left 10px
	vertical-align middle
	background-color #ffffff
	border-right 1px solid #cccccc
	cursor pointer
	text-align left
	&:hover
		background #f9f9f9
		border-bottom 4px solid hsl(153deg 48% 49%)
		background rgba(28, 142, 186, 0.1);

.modulerapport
	display block
	width 200px
	height 60px
	padding-left 10px
	padding-top 20px
	text-align center
	background-color hsl(153deg 48% 49%)
	border-left 1px solid #cccccc
	border-bottom 1px solid #cccccc
	font-weight bold
	cursor pointer
	&:hover
		background primaryA100
	
.sous
	font-weight normal
	padding-left 10px
	padding-top 4px
	font-size .875rem

.infinite-loader
	position relative !important
	right 0 !important
	top 0 !important
	.loader
		display inline-block
		background main_color
		border none
		border-radius 100%
		color #536c78
		.icon
			display block
			animation rotate 1.5s infinite linear

.poweredcityscan
	padding-right 5px


.toreport
	position absolute
	right 10px
	top 9px
	height 60px
.buttonv
	border 0
	border-radius 0
	background primaryA400
	border-radius 18px
	font-style normal
	font-size 12px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 215px
	height 36px
	cursor pointer
	&:hover
		background secondary
	> a
		color #FFFFFF !important

.buttonv_inactive
	border 0
	border-radius 0
	background #AAAAAA
	border-radius 18px
	font-style normal
	font-weight 500
	font-size 12px
	line-height 16px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 215px
	height 36px


.badge
	position absolute
	top 0
	right 0
	height 18px
	width 18px
	line-height 18px
	border-radius 50%
	text-align center
	transform translate(50%,-50%)
	transform-origin 100% 0
	margin 0
	font-size 10px
	vertical-align middle
	color white
.badge-danger
	background red
.badge-success
	background #22c55e


.cs-icon-cityscan:after, .cs-icon-cityscan:before {
	font-family: cs-iconfont-cityscan,serif!important;
}
.icon-logo:before {
	content: "";
}

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
