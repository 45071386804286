<template>
	<transition name="pane-gisements">
		<div class="paneleft pane pane-gisements" :class="app.view">
			<div class="module_ret">
				<div class="head_filter" @click.stop="cv = !cv" title="Filtrer ma recherche">
				</div>
			</div>
			<div class="unmodule_detail_titre" style="height:84px !important;position: absolute;top:60px; border-right: 1px solid #ccc">
				<!--<div class="icone_module"><img src="../assets/img/setec.png" alt="cityscan" style="height:20px;width:60px;text-align:left;"></div>-->
				<div class="thematique">{{ $t('module') }}</div>
				<div class="titre_sommaire"><b>{{ $t('KPI FIL') }}</b></div>
				<button type="submit" class="buttonv" @click="get_pdf();">
					<span v-show="!app.loading">{{ $t('imprimer_en_pdf') }}</span>
				</button>

				<!--<div style="position:absolute;color:grey;bottom:2px;right:5px;font-size:12px">{{ Annonces.length }} offre<span v-if="Annonces.length>1">s</span></div>	-->
			</div>
			<div class="_filtre">
				<div class="programs-list" style="height: 100%; background-color: white;">
					<div class="programs-list-content" v-bar style="background-color: white;">
						<div class="infinite-loader" v-if="app.kpi_fil_volume_loading" style="text-align: center;">
							<div class="text">
								<p>{{$t('Recherche des kpi')}}</p>
								<p>{{ $t('en_cours') }} ...</p>
								<p>&nbsp;</p>
							</div>
							<div class="loader"><icon file="loader" :size="64" style="color:#333" /></div>
						</div>
						<div class="content" style="width:100% !important;" ref="scrollable_programs" v-else>
							<div style="font-weight:500; margin:20px"><b>{{ app.kpi_fil_volume_libelle }}</b></div>												
							<div v-if="app.kpi_fil_volume" style="padding:10px">
								<div class="stat-box-content">
									<div class="table">
										<table id='fil_historique_volume_annee'>
											<tbody>
												<tr>
													<th id="volume_11">{{app.kpi_fil_volume.volume_annee.titre}}</th>
													<th id="volume_12">{{app.kpi_fil_volume.volume_annee.intitule_colonne[0]}}</th>
													<th id="volume_13">{{app.kpi_fil_volume.volume_annee.intitule_colonne[1]}}</th>
													<th id="volume_14">{{app.kpi_fil_volume.volume_annee.intitule_colonne[2]}}</th>
												</tr>
												<tr>
													<th id="volume_21">{{app.kpi_fil_volume.volume_annee.nbprog.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_annee.nbprog.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_annee.nbprog.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_annee.nbprog.tableau[2]}}</td>
												</tr>
												<tr>
													<th id="volume_31">{{app.kpi_fil_volume.volume_annee.mev.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_annee.mev.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_annee.mev.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_annee.mev.tableau[2]}}</td>
												</tr>
												<tr>
													<th id="volume_41">{{app.kpi_fil_volume.volume_annee.vente.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_annee.vente.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_annee.vente.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_annee.vente.tableau[2]}}</td>
												</tr>
												<tr>
													<th id="volume_51">{{app.kpi_fil_volume.volume_annee.offre.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_annee.offre.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_annee.offre.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_annee.offre.tableau[2]}}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<br>
									<div class="stat-box-content" id="fil_chartVolumePromo">
										<div class="graph">
											<highcharts :options="chartOptions_trim" id="fil_chartOptions_1"></highcharts>
										</div>
									</div>

									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>

									<div class="table">
										<table id='fil_historique_volume_trim'>
												<tbody>
												<tr>
													<th id="volume_61">{{app.kpi_fil_volume.volume_trimestre.titre}}</th>
													<th id="volume_62">{{app.kpi_fil_volume.volume_trimestre.intitule_colonne[0]}}</th>
													<th id="volume_63">{{app.kpi_fil_volume.volume_trimestre.intitule_colonne[1]}}</th>
													<th id="volume_64">{{app.kpi_fil_volume.volume_trimestre.intitule_colonne[2]}}</th>
													<th id="volume_65">{{app.kpi_fil_volume.volume_trimestre.intitule_colonne[3]}}</th>
													<th id="volume_66">{{app.kpi_fil_volume.volume_trimestre.intitule_colonne[4]}}</th>
													<th id="volume_67">{{app.kpi_fil_volume.volume_trimestre.intitule_colonne[5]}}</th>
													<th id="volume_68">{{app.kpi_fil_volume.volume_trimestre.intitule_colonne[6]}}</th>
													<th id="volume_69">{{app.kpi_fil_volume.volume_trimestre.intitule_colonne[7]}}</th>
												</tr>
												<tr>
													<th id="volume_71">{{app.kpi_fil_volume.volume_trimestre.nbprog.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre.nbprog.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.nbprog.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.nbprog.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.nbprog.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.nbprog.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.nbprog.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.nbprog.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.nbprog.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="volume_81">{{app.kpi_fil_volume.volume_trimestre.mev.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre.mev.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.mev.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.mev.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.mev.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.mev.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.mev.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.mev.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.mev.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="volume_91">{{app.kpi_fil_volume.volume_trimestre.vente.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre.vente.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.vente.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.vente.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.vente.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.vente.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.vente.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.vente.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.vente.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="volume_101">{{app.kpi_fil_volume.volume_trimestre.offre.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre.offre.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.offre.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.offre.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.offre.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.offre.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.offre.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.offre.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre.offre.tableau[7]}}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<br>
									<div class="stat-box-content" id="fil_chartVolumePromo_trim1"></div>
										<div class="graph">
											<highcharts :options="chartOptions_mois" id="fil_chartVolumePromo_trim"></highcharts>
										</div>
									</div>

									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>

									<div class="table">
										<table id='fil_datatable_destination'>
											<tbody>
												<tr>
													<th id="destination_11"></th>
													<th id="destination_12">{{app.kpi_fil_volume.volume_trimestre_destination.intitule_colonne[0]}}</th>
													<th id="destination_13">{{app.kpi_fil_volume.volume_trimestre_destination.intitule_colonne[1]}}</th>
													<th id="destination_14">{{app.kpi_fil_volume.volume_trimestre_destination.intitule_colonne[2]}}</th>
													<th id="destination_15">{{app.kpi_fil_volume.volume_trimestre_destination.intitule_colonne[3]}}</th>
													<th id="destination_16">{{app.kpi_fil_volume.volume_trimestre_destination.intitule_colonne[4]}}</th>
													<th id="destination_17">{{app.kpi_fil_volume.volume_trimestre_destination.intitule_colonne[5]}}</th>
													<th id="destination_18">{{app.kpi_fil_volume.volume_trimestre_destination.intitule_colonne[6]}}</th>
													<th id="destination_19">{{app.kpi_fil_volume.volume_trimestre_destination.intitule_colonne[7]}}</th>
												</tr>
												<tr>
													<th id="destination_21">{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_inv.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="destination_31">{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.tableau[0] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.tableau[1] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.tableau[2] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.tableau[3] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.tableau[4] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.tableau[5] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.tableau[6] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.tableau[7] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_inv.unite}}</td>
												</tr>
												<tr>
													<th id="destination_41">{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="destination_51">{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.tableau[0] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.tableau[1] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.tableau[2] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.tableau[3] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.tableau[4] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.tableau[5] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.tableau[6] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.tableau[7] + " " + app.kpi_fil_volume.volume_trimestre_destination.pervente_occ.unite}}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<br>

									<div style="width:200px;height:200px;">
										<div class="graph">
											<div>
												<br>
												<Knob id="fil_chart_destination" v-model="this.fil_chart_destination.series[0].data[1].y" valueTemplate="{value}%" readonly :size="200" />
											</div>
										</div>
									</div>
									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>

									<div class="table">
										<table id='fil_datatable_dispositifs'>
											<tbody>
												<tr>
													<th id="dispositif_11"></th>
													<th id="dispositif_12">{{app.kpi_fil_volume.volume_trimestre_dispositif.intitule_colonne[0]}}</th>
													<th id="dispositif_13">{{app.kpi_fil_volume.volume_trimestre_dispositif.intitule_colonne[1]}}</th>
													<th id="dispositif_14">{{app.kpi_fil_volume.volume_trimestre_dispositif.intitule_colonne[2]}}</th>
													<th id="dispositif_15">{{app.kpi_fil_volume.volume_trimestre_dispositif.intitule_colonne[3]}}</th>
													<th id="dispositif_16">{{app.kpi_fil_volume.volume_trimestre_dispositif.intitule_colonne[4]}}</th>
													<th id="dispositif_17">{{app.kpi_fil_volume.volume_trimestre_dispositif.intitule_colonne[5]}}</th>
													<th id="dispositif_18">{{app.kpi_fil_volume.volume_trimestre_dispositif.intitule_colonne[6]}}</th>
													<th id="dispositif_19">{{app.kpi_fil_volume.volume_trimestre_dispositif.intitule_colonne[7]}}</th>
												</tr>
												<tr>
													<th id="dispositif_21">{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="dispositif_31">{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.tableau[0] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.tableau[1] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.tableau[2] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.tableau[3] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.tableau[4] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.tableau[5] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.tableau[6] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.tableau[7] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_aide.unite}}</td>
												</tr>
												<tr>
													<th id="dispositif_41">{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.ventes_libre.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="dispositif_51">{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.tableau[0] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.tableau[1] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.tableau[2] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.tableau[3] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.tableau[4] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.tableau[5] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.tableau[6] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.unite}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.tableau[7] + " " + app.kpi_fil_volume.volume_trimestre_dispositif.pervente_libre.unite}}</td>
												</tr>
											</tbody>
										</table>
									</div>									

									<br>

									<div style="width:200px;height:200px;">
										<div class="graph">
											<div>
												<br>
												<Knob id="fil_chart_aide" v-model="this.fil_chart_aide.series[0].data[1].y" valueTemplate="{value}%" readonly :size="200" />
											</div>
										</div>
									</div>


									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>

									<div class="table">
										<table id='fil_datatable_offre'>
											<tbody>
												<tr>
													<th id="offre_11"></th>
													<th id="offre_12">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[0]}}</th>
													<th id="offre_13">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[1]}}</th>
													<th id="offre_14">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[2]}}</th>
													<th id="offre_15">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[3]}}</th>
													<th id="offre_16">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[4]}}</th>
													<th id="offre_17">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[5]}}</th>
													<th id="offre_18">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[6]}}</th>
													<th id="offre_19">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[7]}}</th>
												</tr>
												<tr>
													<th id="offre_21">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="offre_31">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="offre_41">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier_livre.tableau[7]}}</td>
												</tr>
												<tr>
													<th id="offre_51">{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.libelle}}</th>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[0]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[1]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[2]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[3]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[4]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[5]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[6]}}</td>
													<td>{{app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[7]}}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<br>
									<div class="stat-box-content" id="fil_chartOptions_groff"></div>
										<div class="graph">
											<highcharts :options="fil_chartOptions_groff" id="fil_groff"></highcharts>
										</div>
									</div>


									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>

									<div class="table" v-if="app.kpi_fil_volume && app.kpi_fil_volume.prix_annee_avec_stat && app.kpi_fil_volume.prix_annee_avec_stat.titre">
										<table id='fil_datatable_prix_parking_inclus'>
											<tbody>
												<tr>
													<th id="phitrim_11">{{app.kpi_fil_volume.prix_annee_avec_stat.titre}}</th>
													<th id="phitrim_12">{{app.kpi_fil_volume.prix_annee_avec_stat.intitule_colonne[0]}}</th>
													<th id="phitrim_13">{{app.kpi_fil_volume.prix_annee_avec_stat.intitule_colonne[1]}}</th>
													<th id="phitrim_14">{{app.kpi_fil_volume.prix_annee_avec_stat.intitule_colonne[2]}}</th>
												</tr>
												<tr>
													<th id="phitrim_21">{{app.kpi_fil_volume.prix_annee_avec_stat.prix_vente.libelle}}</th>
													<td>{{enprix(app.kpi_fil_volume.prix_annee_avec_stat.prix_vente.tableau[0])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_annee_avec_stat.prix_vente.tableau[1])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_annee_avec_stat.prix_vente.tableau[2])}}</td>
												</tr>
												<tr>
													<th id="phitrim_31">{{app.kpi_fil_volume.prix_annee_avec_stat.prix_vente_evolution.libelle}}</th>
													<td>{{percent(app.kpi_fil_volume.prix_annee_avec_stat.prix_vente_evolution.tableau[0])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_annee_avec_stat.prix_vente_evolution.tableau[1])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_annee_avec_stat.prix_vente_evolution.tableau[2])}}</td>
												</tr>
												<tr>
													<th id="phitrim_41">{{app.kpi_fil_volume.prix_annee_avec_stat.prix_offre.libelle}}</th>
													<td>{{enprix(app.kpi_fil_volume.prix_annee_avec_stat.prix_offre.tableau[0])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_annee_avec_stat.prix_offre.tableau[1])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_annee_avec_stat.prix_offre.tableau[2])}}</td>
												</tr>
												<tr>
													<th id="phitrim_51">{{app.kpi_fil_volume.prix_annee_avec_stat.prix_offre_evolution.libelle}}</th>
													<td>{{percent(app.kpi_fil_volume.prix_annee_avec_stat.prix_offre_evolution.tableau[0])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_annee_avec_stat.prix_offre_evolution.tableau[1])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_annee_avec_stat.prix_offre_evolution.tableau[2])}}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<br>


									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>

									<div class="table" v-if="app.kpi_fil_volume && app.kpi_fil_volume.prix_trimestre_avec_stat && app.kpi_fil_volume.prix_trimestre_avec_stat.titre">
										<table>
											<tbody class="table">
												<tr>
													<th id="phitrim_61">{{app.kpi_fil_volume.prix_trimestre_avec_stat.titre}}</th>
													<th id="phitrim_62">{{app.kpi_fil_volume.prix_trimestre_avec_stat.intitule_colonne[0]}}</th>
													<th id="phitrim_63">{{app.kpi_fil_volume.prix_trimestre_avec_stat.intitule_colonne[1]}}</th>
													<th id="phitrim_64">{{app.kpi_fil_volume.prix_trimestre_avec_stat.intitule_colonne[2]}}</th>
													<th id="phitrim_65">{{app.kpi_fil_volume.prix_trimestre_avec_stat.intitule_colonne[3]}}</th>
													<th id="phitrim_66">{{app.kpi_fil_volume.prix_trimestre_avec_stat.intitule_colonne[4]}}</th>
													<th id="phitrim_67">{{app.kpi_fil_volume.prix_trimestre_avec_stat.intitule_colonne[5]}}</th>
													<th id="phitrim_68">{{app.kpi_fil_volume.prix_trimestre_avec_stat.intitule_colonne[6]}}</th>
													<th id="phitrim_69">{{app.kpi_fil_volume.prix_trimestre_avec_stat.intitule_colonne[7]}}</th>
												</tr>
												<tr>
													<th id="phitrim_71">{{app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.libelle}}</th>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[0])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[1])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[2])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[3])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[4])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[5])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[6])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[7])}}</td>
												</tr>
												<tr>
													<th id="phitrim_81">{{app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.libelle}}</th>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.tableau[0])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.tableau[1])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.tableau[2])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.tableau[3])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.tableau[4])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.tableau[5])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.tableau[6])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente_evolution.tableau[7])}}</td>
												</tr>
												<tr>
													<th id="phitrim_91">{{app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.libelle}}</th>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[0])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[1])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[2])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[3])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[4])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[5])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[6])}}</td>
													<td>{{enprix(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[7])}}</td>
												</tr>
												<tr>
													<th id="phitrim_101">{{app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.libelle}}</th>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.tableau[0])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.tableau[1])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.tableau[2])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.tableau[3])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.tableau[4])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.tableau[5])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.tableau[6])}}</td>
													<td>{{percent(app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre_evolution.tableau[7])}}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<br>
									<div class="graph">
										<highcharts :options="fil_chartOptions_prix_trim_pi" id='fil_chartOptions_prix_trim_pi'></highcharts>
									</div>


									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>

									<div class="table" v-if="app.kpi_fil_volume && app.kpi_fil_volume.rythme_annee && app.kpi_fil_volume.rythme_annee.titre">
										<table id='fil_datatable_rythmea'>
											<tbody class="table">
												<tr>
													<th id="ry_11">{{app.kpi_fil_volume.rythme_annee.titre}}</th>
													<th id="ry_12">{{app.kpi_fil_volume.rythme_annee.intitule_colonne[0]}}</th>
													<th id="ry_13">{{app.kpi_fil_volume.rythme_annee.intitule_colonne[1]}}</th>
													<th id="ry_14">{{app.kpi_fil_volume.rythme_annee.intitule_colonne[2]}}</th>
												</tr>
												<tr>
													<th id="ry_21">{{app.kpi_fil_volume.rythme_annee.te.libelle}}</th>
													<td>{{percent(app.kpi_fil_volume.rythme_annee.te.tableau[0])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_annee.te.tableau[1])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_annee.te.tableau[2])}}</td>
												</tr>
												<tr>
													<th id="ry_31">{{app.kpi_fil_volume.rythme_annee.duree_com.libelle}}</th>
													<td>{{enmois(app.kpi_fil_volume.rythme_annee.duree_com.tableau[0])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_annee.duree_com.tableau[1])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_annee.duree_com.tableau[2])}}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<br>

									<div class="table" v-if="app.kpi_fil_volume && app.kpi_fil_volume.rythme_trimestre && app.kpi_fil_volume.rythme_trimestre.titre">
										<table id='fil_datatable_rythmet'>
											<tbody class="table">
												<tr>
													<th id="ry_41">{{app.kpi_fil_volume.rythme_trimestre.titre}}</th>
													<th id="ry_42">{{app.kpi_fil_volume.rythme_trimestre.intitule_colonne[0]}}</th>
													<th id="ry_43">{{app.kpi_fil_volume.rythme_trimestre.intitule_colonne[1]}}</th>
													<th id="ry_44">{{app.kpi_fil_volume.rythme_trimestre.intitule_colonne[2]}}</th>
													<th id="ry_45">{{app.kpi_fil_volume.rythme_trimestre.intitule_colonne[3]}}</th>
													<th id="ry_46">{{app.kpi_fil_volume.rythme_trimestre.intitule_colonne[4]}}</th>
													<th id="ry_47">{{app.kpi_fil_volume.rythme_trimestre.intitule_colonne[5]}}</th>
													<th id="ry_48">{{app.kpi_fil_volume.rythme_trimestre.intitule_colonne[6]}}</th>
													<th id="ry_49">{{app.kpi_fil_volume.rythme_trimestre.intitule_colonne[7]}}</th>
												</tr>
												<tr>
													<th id="ry_51">{{app.kpi_fil_volume.rythme_trimestre.te.libelle}}</th>
													<td>{{percent(app.kpi_fil_volume.rythme_trimestre.te.tableau[0])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_trimestre.te.tableau[1])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_trimestre.te.tableau[2])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_trimestre.te.tableau[3])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_trimestre.te.tableau[4])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_trimestre.te.tableau[5])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_trimestre.te.tableau[6])}}</td>
													<td>{{percent(app.kpi_fil_volume.rythme_trimestre.te.tableau[7])}}</td>
												</tr>
												<tr>
													<th id="ry_61">{{app.kpi_fil_volume.rythme_trimestre.duree_com.libelle}}</th>
													<td>{{enmois(app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[0])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[1])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[2])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[3])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[4])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[5])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[6])}}</td>
													<td>{{enmois(app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[7])}}</td>
												</tr>
											</tbody>
										</table>
									</div>

									<br>
									<div class="graph">
										<highcharts :options="fil_chartOptions_rythme_trim" id="fil_chartOptions_rythme_trim"></highcharts>
									</div>

									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>
								<!--
									<DataTable showGridlines stripedRows rowGroupMode="subheader" groupRowsBy="secteur" sortMode="single" sortField="secteur" dataKey="id" class="synoptique" id="synoprog"  :scrollable="true" scrollHeight="400px"
										:value="synojson"
										:sortOrder="1"
									>
										<ColumnGroup type="header">
											<Row>
												<Column field="id" :header="$t('ID')" :rowspan="2" :headerStyle="{width: '80px'}" :sortable="true"/>
												<Column field="nom_promoteur" :header="$t('programme')" :rowspan="2" :sortable="true"/>
												<Column field="forme" :header="$t('f.u.')" :rowspan="2" :headerStyle="{width: '40px'}" />
												<Column field="date_mev" :header="$t('MEV')" :rowspan="2" :headerStyle="{width: '80px'}"/>
												<Column field="date_liv" :header="$t('LIV')" :rowspan="2" :headerStyle="{width: '80px'}"/>
												<Column field="date_fin" :header="$t('FIN')" :rowspan="2" :headerStyle="{width: '80px'}"/>
												<Column :header="$t('offre_/_s.i.')" :colspan="6" />
												<Column field="vente_mois" :header="$t('vm')" :rowspan="2" :headerStyle="{width: '50px'}"/>
												<Column field="enqpv" :header="$t('qpv')" :rowspan="2" :headerStyle="{width: '50px'}"/>
												<Column field="inv" header="Inv." :rowspan="2" :headerStyle="{width: '50px'}"/>
												<Column v-if="tvar" :header="$t('tva_reduite')" :colspan="2" />
												<Column v-if="tvapa" :header="$t('tva_pleine_aidee')" :colspan="2" />
												<Column v-if="tvapl" :header="$t('tva_pleine_libre')" :colspan="2" />
												<Column v-if="avecbrs" :field="$t('redevance')" :header="$t('brs')" :rowspan="2"/>
											</Row>
											<Row>
												<Column field="avancement" :header="$t('avancement')" :sortable="true" :headerStyle="{width: '130px'}"/>
												<Column field="offre_t1" :header="$t('T1')" :styles="{'width': '60px' }"/>
												<Column field="offre_t2" :header="$t('T2')" :styles="{'width': '60px' }"/>
												<Column field="offre_t3" :header="$t('T3')" :styles="{'width': '60px' }"/>
												<Column field="offre_t4" :header="$t('T4')" :styles="{'width': '60px' }"/>
												<Column field="offre_t5" :header="$t('T5')" :styles="{'width': '60px' }"/>
												<Column v-if="tvar" field="prix_tva_reduite_hors_parking" :header="$t('h.p.')" :sortable="true"/>
												<Column v-if="tvar" field="prix_tva_reduite_avec_parking" :header="$t('p.i.')" :sortable="true"/>
												<Column v-if="tvapa" field="prix_tva_pleine_aide_hors_parking" :header="$t('h.p.')" :sortable="true"/>
												<Column v-if="tvapa" field="prix_tva_pleine_aide_avec_parking" :header="$t('p.i.')" :sortable="true"/>
												<Column v-if="tvapl" field="prix_tva_pleine_libre_hors_parking" :header="$t('h.p.')" :sortable="true"/>
												<Column v-if="tvapl" field="prix_tva_pleine_libre_avec_parking" :header="$t('p.i.')" :sortable="true"/>
											</Row>
										</ColumnGroup>
										
										<Column field="id" :header="$t('nom')" :styles="{'width': '50px', 'border-left': '0' }">
											<template #body="{data}">
												<div style="width:100%;text-align:center;">
													<div style="border-radius: 50%; height:28px; width:28px;line-height:28px;color:white;background-color: #79397f;">
														{{ data.id }}
													</div>
												</div>
											</template>				
										</Column>
										<Column field="nom_promoteur" :header="$t('nom')" :styles="{'max-width': '300px', 'border-left': '0' }">
											<template #body="{data}">
												<div style="width: 100%;height: 100%;display: flex;align-items: center;cursor: pointer;">
													<div><p class="ellipsis-text2"><b>{{ data.nom }}</b></p><p class="ellipsis-text2">{{ data.promoteur }}</p><p class="ellipsis-text2">{{ data.adresse }}</p></div>
												</div>
											</template>
										</Column>
										<Column field="forme" :header="$t('f.u.')">
											<template #body="{data}">
												<div class="forme-urbaine" v-if="data.forme_urbaine.toUpperCase()=='COLLECTIF'"><icon file="building" :size="24" style="color:#cccccc" /></div>
												<div class="forme-urbaine" v-else><icon file="home" :size="24" style="color:#cccccc" /></div>
											</template>
										</Column>
										<Column field="date_mev" :header="$t('mev')">
											<template #body="{data}">
												<div style="text-align: center;width:100%">
												{{transformDate(data.date_mev)}}
												</div>
											</template>
										</Column>

										<Column field="date_liv" :header="$t('LIV')">
											<template #body="{data}">
												<div style="text-align: center;width:100%">
													{{transformDate(data.date_liv)}}
												</div>
												</template>
										</Column>

										<Column field="date_fin" :header="$t('FIN')">
											<template #body="{data}">
												<div style="text-align: center;width:100%">
													{{transformDate(data.date_fin)}}
												</div>
											</template>
										</Column>
										<Column field="avancement" :header="$t('avancement')" sortable :showFilterMatchModes="false" :styles="{'min-width': '5rem'}">
											<template #body="{data}">
												<div style="text-align: center; padding-bottom: 5px;">{{ data.offre_total }}</div>
												<ProgressBar :value="data.avancement" :showValue="false" />
											</template>
											<template #filter="{filterModel}">
												<Slider v-model="filterModel.value" range class="m-3"></Slider>
												<div class="flex align-items-center justify-content-between px-2">
													<span>{{filterModel.value ? filterModel.value[0] : 0}}</span>
													<span>{{filterModel.value ? filterModel.value[1] : 100}}</span>
												</div>
											</template>
										</Column>
										<Column field="offre_t1" :header="$t('repartition')">
											<template #body="{data}">
												<div style="font-weight: 500;text-align: center;width:100%" v-if="data.stock_ini_typo && data.stock_ini_typo[0]"
													:class="{
														pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[0] == data.offre_typo[0] && data.stock_ini_typo[0]), 
														pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[0] && data.stock_ini_typo[0])
													}">
													{{ data.offre_typo ? data.offre_typo[0] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[0] : '-' }}
												</div>
												<div style="text-align: center;width:100%" v-else>-</div>
											</template>
										</Column>
										<Column field="offre_t2" :header="$t('repartition')">
											<template #body="{data}">
												<div style="font-weight: 500;text-align: center;width:100%"  v-if="data.stock_ini_typo && data.stock_ini_typo[1]"
													:class="{
														pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[1] == data.offre_typo[1] && data.stock_ini_typo[1]), 
														pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[1] && data.stock_ini_typo[1])
													}">
													{{ data.offre_typo ? data.offre_typo[1] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[1] : '-' }}
												</div>
												<div style="text-align: center;width:100%" v-else>-</div>
											</template>
										</Column>
										<Column field="offre_t3" :header="$t('repartition')">
											<template #body="{data}">
												<div style="font-weight: 500;text-align: center;width:100%"  v-if="data.stock_ini_typo && data.stock_ini_typo[2]"
													:class="{
														pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[2] == data.offre_typo[2] && data.stock_ini_typo[2]), 
														pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[2] && data.stock_ini_typo[2])
													}">
													{{ data.offre_typo ? data.offre_typo[2] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[2] : '-' }}
												</div>
												<div style="text-align: center;width:100%" v-else>-</div>
											</template>
										</Column>
										<Column field="offre_t4" :header="$t('repartition')">
											<template #body="{data}">
												<div style="font-weight: 500;text-align: center;width:100%"  v-if="data.stock_ini_typo && data.stock_ini_typo[3]"
													:class="{
														pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[3] == data.offre_typo[3] && data.stock_ini_typo[3]), 
														pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[3] && data.stock_ini_typo[3])
													}">
													{{ data.offre_typo ? data.offre_typo[3] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[3] : '-' }}
												</div>
												<div style="text-align: center;width:100%" v-else>-</div>
											</template>
										</Column>
										<Column field="offre_t5" :header="$t('repartition')">
											<template #body="{data}">
												<div style="font-weight: 500;text-align: center;width:100%"  v-if="data.stock_ini_typo && data.stock_ini_typo[4]"
													:class="{
														pasdevente : (data.stock_ini_typo && data.offre_typo && data.stock_ini_typo[4] == data.offre_typo[4] && data.stock_ini_typo[4]), 
														pasdoffre : (data.stock_ini_typo && data.offre_typo && !data.offre_typo[4] && data.stock_ini_typo[4])
													}">
													{{ data.offre_typo ? data.offre_typo[4] : '-' }} / {{ data.stock_ini_typo ? data.stock_ini_typo[4] : '-' }}
												</div>
												<div style="text-align: center;width:100%" v-else>-</div>
											</template>
										</Column>
										<Column field="vente_mois" :header="$t('v/m')" :sortable="true">
											<template #body="{data}">
												<div style="text-align: center;">{{ data.vente_mois }}</div>
											</template>
										</Column>
										<Column field="enqpv" :header="$t('qpv')" :sortable="true">
											<template #body="{data}">
												<div style="text-align: center;">{{ data.enqpv }}</div>
											</template>
										</Column>
										<Column field="inv" :header="$t('Inv.')" :sortable="true">
											<template #body="{data}">
												<Knob v-model="data.per_inv_number" valueTemplate="{value}%" readonly :size="45" />
											</template>
										</Column>
										<Column v-if="tvar" field="prix_tva_reduite_hors_parking" :header="$t('h.p.')">
											<template #body="{data}">
												<div class="prix">{{ data.prix_tva_reduite_hors_parking }}</div>
											</template>
										</Column>
										<Column v-if="tvar" field="prix_tva_reduite_avec_parking" :header="$t('p.i.')">
											<template #body="{data}">
												<div class="prix">{{ data.prix_tva_reduite_avec_parking }}</div>
											</template>
										</Column>
										<Column v-if="tvapa" field="prix_tva_pleine_aide_hors_parking" :header="$t('h.p.')">
											<template #body="{data}">
												<div class="prix">{{ data.prix_tva_pleine_aide_hors_parking }}</div>
											</template>
										</Column>
										<Column v-if="tvapa" field="prix_tva_pleine_aide_avec_parking" :header="$t('p.i.')">
											<template #body="{data}">
												<div class="prix">{{ data.prix_tva_pleine_aide_avec_parking }}</div>
											</template>
										</Column>
										<Column v-if="tvapl" field="prix_tva_pleine_libre_hors_parking" :header="$t('h.p.')">
											<template #body="{data}">
												<div class="prix">{{ data.prix_tva_pleine_libre_hors_parking }}</div>
											</template>
										</Column>
										<Column v-if="tvapl" field="prix_tva_pleine_libre_avec_parking" :header="$t('p.i.')">
											<template #body="{data}">
												<div class="prix">{{ data.prix_tva_pleine_libre_avec_parking }}</div>
											</template>
										</Column>
										<Column v-if="avecbrs" :field="$t('redevance')" :header="$t('redevance_brs')" :sortable="true">
											<template #body="{data}">
												<div class="prix">{{ enprix2(data.redevance) }}</div>
											</template>
										</Column>
										<template #groupheader="slotProps">
											<span class="image-text">{{slotProps.data.secteur}}</span>
										</template>
									</DataTable>
								-->
									
									<div style="width:100%;height:1px;background-color:#ccc;margin-top:20px;margin-bottom:30px"></div>


									<DataTable :value="Traite(app.kpi_fil_volume.palmares)" sortField="vte" :sortOrder="-1" responsiveLayout="scroll">
										<ColumnGroup type="header">
											<Row>
												<Column field="lib" header="Secteur" :rowspan="3"  :sortable="true" frozen/>
												<Column field="ofr" header="Offre" :rowspan="3" :sortable="true" />
												<Column field="vte" header="Vente" :rowspan="3"  :sortable="true"/>
												<Column header="Part des ventes" :rowspan="2" :colspan="2" />
												<Column header="Prix" :colspan="8" />
											</Row>
											<Row>
												<Column header="de l'offre" :colspan="4" />
												<Column header="des ventes" :colspan="4" />
											</Row>
											<Row>
												<Column field="inv" header="inv." :sortable="true"/>
												<Column field="aid" header="aidé" :sortable="true"/>
												<Column field="prx_ofr_hpk" header="H.P." :sortable="true"/>
												<Column field="prx_ofr_hpk_evl" header="evol" :sortable="true"/>
												<Column field="prx_ofr_pki" header="P.I." :sortable="true"/>
												<Column field="prx_ofr_pki_evl" header="evol" :sortable="true"/>
												<Column field="prx_vte_hpk" header="H.P." :sortable="true"/>
												<Column field="prx_vte_hpk_evl" header="evol" :sortable="true"/>
												<Column field="prx_vte_pki" header="P.I." :sortable="true"/>
												<Column field="prx_vte_pki_evl" header="evol" :sortable="true"/>
											</Row>
										</ColumnGroup>

										<Column field="lib" header="Secteur" sortable>
											<template #body="{data}">
												<div style="white-space: nowrap;">{{data.lib}}</div>
											</template>
										</Column>
										<Column field="ofr" header="Offre" sortable :styles="{'min-width':'200px'}"></Column>
										<Column field="vte" header="Vente" sortable :styles="{'min-width':'200px'}"></Column>
										<Column field="inv" header="Part ventes inv." :sortable="true">
											<template #body="{data}">
												<Knob v-model="data.inv" valueTemplate="{value}%" readonly :size="45" />
											</template>
										</Column>
										<Column field="aid" header="Part ventes aidés" :sortable="true">
											<template #body="{data}">
												<Knob v-model="data.aid" valueTemplate="{value}%" readonly :size="45" />
											</template>
										</Column>


										<Column field="prx_ofr_hpk" header="Prix de l'offre H.P." sortable>
											<template #body="{data}">
												<div class="prix">{{ formatCurrency(data.prx_ofr_hpk) }}</div>
											</template>
										</Column>
										<Column field="prx_ofr_hpk_evl" header="evol." sortable>
											<template #body="{data}">
												<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="data.prx_ofr_hpk_evl>0"></i>
												<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="data.prx_ofr_hpk_evl<0"></i>
												<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="data.prx_ofr_hpk_evl>0">+ {{data.prx_ofr_hpk_evl}} %</span>
												<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="data.prx_ofr_hpk_evl<0">{{data.prx_ofr_hpk_evl}} %</span>
											</template>
										</Column>

										<Column field="prx_ofr_pki" header="Prix de l'offre P.I." sortable>
											<template #body="{data}">
												<div class="prix">{{ formatCurrency(data.prx_ofr_pki) }}</div>
											</template>
										</Column>
										<Column field="prx_ofr_pki_evl" header="evol." sortable>
											<template #body="{data}">
												<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="data.prx_ofr_pki_evl>0"></i>
												<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="data.prx_ofr_pki_evl<0"></i>
												<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="data.prx_ofr_pki_evl>0">+ {{data.prx_ofr_pki_evl}} %</span>
												<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="data.prx_ofr_pki_evl<0">{{data.prx_ofr_pki_evl}} %</span>
											</template>
										</Column>

										<Column field="prx_vte_hpk" header="Prix de vente H.P." sortable>
											<template #body="{data}">
												<div class="prix">{{ formatCurrency(data.prx_vte_hpk) }}</div>
											</template>
										</Column>
										<Column field="prx_vte_hpk_evl" header="evol." sortable>
											<template #body="{data}">
												<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="data.prx_vte_hpk_evl>0"></i>
												<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="data.prx_vte_hpk_evl<0"></i>
												<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="data.prx_vte_hpk_evl>0">+ {{data.prx_vte_hpk_evl}} %</span>
												<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="data.prx_vte_hpk_evl<0">{{data.prx_vte_hpk_evl}} %</span>
											</template>
										</Column>

										<Column field="prx_vte_pki" header="Prix de vente P.I." sortable>
											<template #body="{data}">
												<div class="prix">{{ formatCurrency(data.prx_vte_pki) }}</div>
											</template>
										</Column>
										<Column field="prx_vte_pki_evl" header="evol." sortable>
											<template #body="{data}">
												<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="data.prx_vte_pki_evl>0"></i>
												<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="data.prx_vte_pki_evl<0"></i>
												<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="data.prx_vte_pki_evl>0">+ {{data.prx_vte_pki_evl}} %</span>
												<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="data.prx_vte_pki_evl<0">{{data.prx_vte_pki_evl}} %</span>
											</template>
										</Column>
										
									</DataTable>






							</div>
						</div>
					</div>
				</div>
			</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Textarea  from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import SwitchButtonDpe from './global/SwitchButtonDpe'
import SwitchButton from './global/SwitchButton'
import Slider from 'primevue/slider';
import { Chart } from 'highcharts-vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Knob from 'primevue/knob';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import ImagePreview from 'primevue/imagepreview';
import SpeedDial from 'primevue/speeddial';
import ProgressBar from 'primevue/progressbar';

export default {
	name: 'PaneGisements',

	data: function() {
		return {
			fil_chart_aide: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
					style: {
						fontFamily: 'Nunito',
					}
				},
				tooltip: {
					pointFormat: '<strong>{point.percentage:.0f}%</strong>'
				},
				plotOptions: {
					pie: {
						allowPointSelect: false,
						dataLabels: {
							enabled: false
						},
						showInLegend: true
					}
				},
				series: [{
					name: 'Brands',
					colorByPoint: true,
					data: [
						{
							name: 'Occupant',
							y: 85,
							color: '#ccc',
						}, {
							name: 'Investisseur',
							y: 15,
							color: '#2196F3',
						}
					]
				}]
			},

			fil_chart_destination: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
					style: {
						fontFamily: 'Nunito',
					}
				},
				tooltip: {
					pointFormat: '<strong>{point.percentage:.0f}%</strong>'
				},
				plotOptions: {
					pie: {
						allowPointSelect: false,
						dataLabels: {
							enabled: false
						},
						showInLegend: true
					}
				},
				series: [{
					name: 'Brands',
					colorByPoint: true,
					data: [
						{
							name: 'Occupant',
							y: 85,
							color: '#ccc',
						}, {
							name: 'Investisseur',
							y: 15,
							color: '#2196F3',
						}
					]
				}]
			},

			fil_chartOptions_rythme_trim: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#2196F3',
						name: "Durée moyenne de commercialisation",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},

			fil_chartOptions_prix_trim_pi: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#f44336',
						name: "Offre commerciale",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "Ventes",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},					
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash',
					labels: {
						formatter: function () {
							return this.value + " €";
						},
					},
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},

			fil_chartOptions_groff: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'area',
					style: {
						fontFamily: 'Nunito',
					}
				},
                xAxis: {
                        categories: ['1750', '1800', '1850', '1900', '1950', '1999', '2050'],
                        tickmarkPlacement: 'on',
                        title: {
                            enabled: false
                        }
                },
                yAxis: {
                    labels: {
                        format: '{value}%'
                    },
                    title: {
                        enabled: false
                    }
                },
                plotOptions: {
                    area: {
                        stacking: 'percent',
                        lineColor: '#ffffff',
                        lineWidth: 1,
                        marker: {
                            lineWidth: 1,
                            lineColor: '#ffffff'
                        },
                        accessibility: {
                            pointDescriptionFormatter: function (point) {
                                function round(x) {
                                    return Math.round(x * 100) / 100;
                                }
                                return (point.index + 1) + ', ' + point.category + ', ' +
                                    point.y + ' millions, ' + round(point.percentage) + '%, ' +
                                    point.series.name;
                            }
                        }
                    }
                },
				series: [
					{
						color: '#f44336',
						name: "Sur plan",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "En chantier",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#2196F3',
						name: "livrée",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
			},
			chartOptions_trim: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#f44336',
						name: "Offre commerciale",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "Ventes",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#2196F3',
						name: "Mises en vente",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			chartOptions_mois: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#f44336',
						name: "Offre commerciale",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "Ventes",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#2196F3',
						name: "Mises en vente",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			defaultImageUrl: 'https://www.myadequation.fr/EFOCUS./no-camera2.png'

		}
	},

	mounted: function() {
		this.maj_trimestre()
		this.maj_mois()
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		synojson:function(){
			let tabsyno = [];
			this.avecbrs = false;
			this.tvapl = false;
			this.tvapa = false;
			this.tvar = false;
			if (this.app.kpi_fil_volume && this.app.kpi_fil_volume.synoptique) {
				for(let i = 0; i < this.app.kpi_fil_volume.synoptique.length; i++){
				for(let k = 0; k < this.app.kpi_fil_volume.synoptique[i].tableau.length; k++){
					let p = this.app.kpi_fil_volume.synoptique[i].tableau[k]
					p.id = this.app.kpi_fil_volume.synoptique[i].tableau[k].id
					p.secteur = this.app.kpi_fil_volume.synoptique[i].secteur
					p.date_mev = ''
					p.date_liv = ''
					p.date_fin = ''
					p.enqpv = ''
					p.forme = p.forme_urbaine.toUpperCase().substr(0,3)
					p.offre_sur_stock = ['', '', '', '', '']
					p.prix_tva_reduite_hors_parking = ''
					p.prix_tva_reduite_avec_parking = ''
					p.prix_tva_pleine_aide_hors_parking = ''
					p.prix_tva_pleine_aide_avec_parking = ''
					p.prix_tva_pleine_libre_hors_parking = ''
					p.prix_tva_pleine_libre_avec_parking = ''
					if (p.mev)
					p.date_mev = p.mev.substr(4,2) + '/' + p.mev.substr(2,2)
					if (p.liv)
					p.date_liv = p.liv.substr(4,2) + '/' + p.liv.substr(2,2)
					if (p.fin && p.fin!='0')
					p.date_fin = p.fin.substr(4,2) + '/' + p.fin.substr(2,2)
					if (p.qpv!='0')
					p.enqpv = 'X'
					for (let l=0; l<5;l++) {
						if (p.stock_ini_typo[l])
						p.offre_sur_stock[l] = p.offre_typo[l] + '/' + p.stock_ini_typo[l]
					}
					p.inv = p.per_inv
					p.per_inv_number = Math.round(p.per_inv)
					if (p.prix.tva_reduite.hors_parking) {
						p.prix_tva_reduite_hors_parking =  p.prix.tva_reduite.hors_parking
						this.tvar = true
					} 
					if (p.prix.tva_reduite.avec_parking) {
						p.prix_tva_reduite_avec_parking =  p.prix.tva_reduite.avec_parking
						this.tvar = true
					}
					if (p.prix.tva_pleine_aide.hors_parking) {
						p.prix_tva_pleine_aide_hors_parking =  p.prix.tva_pleine_aide.hors_parking
						this.tvapa = true
					}
					if (p.prix.tva_pleine_aide.avec_parking) {
						p.prix_tva_pleine_aide_avec_parking =  p.prix.tva_pleine_aide.avec_parking
						this.tvapa = true
					}
					if (p.prix.tva_pleine_libre.hors_parking) {
						p.prix_tva_pleine_libre_hors_parking =  p.prix.tva_pleine_libre.hors_parking
						this.tvapl = true
					}
					if (p.prix.tva_pleine_libre.avec_parking) {
						p.prix_tva_pleine_libre_avec_parking =  p.prix.tva_pleine_libre.avec_parking
						this.tvapl = true
					}
					if (p.brs_redevance) {
						this.avecbrs = true
					}
					p.offre_total = p.offre + '/' + p.stock_ini
					p.avancement = (p.stock_ini - p.offre)/p.stock_ini * 100
					p.offre_t1 = p.offre_sur_stock[0]
					p.offre_t2 = p.offre_sur_stock[1]
					p.offre_t3 = p.offre_sur_stock[2]
					p.offre_t4 = p.offre_sur_stock[3]
					p.offre_t5 = p.offre_sur_stock[4]
					p.redevance =  p.brs_redevance
					p.nom_promoteur = p.nom + " - " + p.promoteur
					p.adresse_ville = p.adresse + " - " + p.ville
					tabsyno.push(p);
				}
				}
			}
			
			return tabsyno
		},
	}),


	watch: {
		'app.kpi_fil_volume': function(newval) {
			if (newval) {
				this.maj_trimestre()
				this.maj_mois()
			}
		},
	},
	methods: {
		get_pdf: function() {
			this.$store.dispatch('get_report_fil_pdf_dynamic', {})
		},
		enprix2: function (str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				return n.toString() + " €"
			}
			return ""
		},
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},
        formatCurrency(value) {
			if (value) {
				return value.toLocaleString('fr-FR', {style: 'currency', currency: 'EUR',minimumFractionDigits: 0,maximumFractionDigits: 0});
			} else return "-"
        },
		Traite: function() {
			var t = []
			if (this.app.kpi_fil_volume && this.app.kpi_fil_volume.palmares) {
				for (let index = 0; index < this.app.kpi_fil_volume.palmares.length; index++) {
				const element = this.app.kpi_fil_volume.palmares[index];
//				if (element.ofr || element.vte || element.mev) {
					t.push(element)
//				}
			}
			}
			return t
		},
		maj_trimestre: function() {
			if (this.app.kpi_fil_volume && this.app.kpi_fil_volume.volume_annee && this.app.kpi_fil_volume.volume_annee.offre && this.app.kpi_fil_volume.volume_annee.offre.tableau) {
				this.chartOptions_trim.series[0].data = [
					this.app.kpi_fil_volume.volume_annee.offre.tableau[0], 
					this.app.kpi_fil_volume.volume_annee.offre.tableau[1], 
					this.app.kpi_fil_volume.volume_annee.offre.tableau[2]
				]
				this.chartOptions_trim.series[1].data = [this.app.kpi_fil_volume.volume_annee.vente.tableau[0], this.app.kpi_fil_volume.volume_annee.vente.tableau[1], this.app.kpi_fil_volume.volume_annee.vente.tableau[2]]
				this.chartOptions_trim.series[2].data = [this.app.kpi_fil_volume.volume_annee.mev.tableau[0], this.app.kpi_fil_volume.volume_annee.mev.tableau[1], this.app.kpi_fil_volume.volume_annee.mev.tableau[2]]
				this.chartOptions_trim.xAxis.categories= [this.app.kpi_fil_volume.volume_annee.intitule_colonne[0], this.app.kpi_fil_volume.volume_annee.intitule_colonne[1], this.app.kpi_fil_volume.volume_annee.intitule_colonne[2]]
			}
		},

		maj_mois: function() {

			let nbaide = 0
			let tout = 0
			let paraide = 0
			let parlib = 0
			for (let j=4; j<8; j++) {
				if (this.app.kpi_fil_volume && this.app.kpi_fil_volume.volume_trimestre_dispositif && this.app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide) {
					nbaide += this.app.kpi_fil_volume.volume_trimestre_dispositif.ventes_aide.tableau[j]
					tout += this.app.kpi_fil_volume.volume_trimestre.vente.tableau[j]
				}
			}
			if (nbaide >= 0) {
				if (nbaide >= tout) {
					paraide = 100
					parlib = 0
				} else {
					paraide = Math.round(nbaide / tout * 100)
					parlib = 100 - paraide
				}
			} else {
				if (tout > 0) {
					paraide = 0
					parlib = 100
				} else {
					paraide = 0
					parlib = 0
				}
			}
			this.fil_chart_aide.series[0].data[0].y= paraide
			this.fil_chart_aide.series[0].data[1].y= parlib
			
			let nbocc = 0
			let parocc = 0
			let parinv = 0
			for (let j=4; j<8; j++) {
				if (this.app.kpi_fil_volume && this.app.kpi_fil_volume.volume_trimestre_destination && this.app.kpi_fil_volume.volume_trimestre_destination.ventes_occ) {
					nbocc += this.app.kpi_fil_volume.volume_trimestre_destination.ventes_occ.tableau[j]
				}
			}
			if (nbocc >= 0) {
				if (nbocc >= tout) {
					parocc = 100
					parinv = 0
				} else {
					parocc = Math.round(nbocc / tout * 100)
					parinv = 100 - parocc
				}
			} else {
				if (tout > 0) {
					parocc = 0
					parinv = 100
				} else {
					parocc = 0
					parinv = 0
				}
			}
			this.fil_chart_destination.series[0].data[0].y= parocc
			this.fil_chart_destination.series[0].data[1].y= parinv

			if (this.app.kpi_fil_volume && this.app.kpi_fil_volume.volume_trimestre && this.app.kpi_fil_volume.volume_trimestre.offre && this.app.kpi_fil_volume.volume_trimestre.offre.tableau) {
				this.fil_chartOptions_rythme_trim.series[0].data = [
					this.app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[0], 
					this.app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[1], 
					this.app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[2], 
					this.app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[3], 
					this.app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[4],
					this.app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[5],
					this.app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[6],
					this.app.kpi_fil_volume.rythme_trimestre.duree_com.tableau[7]
				]
				this.fil_chartOptions_rythme_trim.xAxis.categories= [
					this.app.kpi_fil_volume.rythme_trimestre.intitule_colonne[0], 
					this.app.kpi_fil_volume.rythme_trimestre.intitule_colonne[1], 
					this.app.kpi_fil_volume.rythme_trimestre.intitule_colonne[2], 
					this.app.kpi_fil_volume.rythme_trimestre.intitule_colonne[3], 
					this.app.kpi_fil_volume.rythme_trimestre.intitule_colonne[4],
					this.app.kpi_fil_volume.rythme_trimestre.intitule_colonne[5],
					this.app.kpi_fil_volume.rythme_trimestre.intitule_colonne[6],
					this.app.kpi_fil_volume.rythme_trimestre.intitule_colonne[7]
				]
				
				
				
				let min = 0
				let max = 0
				let nboffre_pi = [this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[0], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[1], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[2], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[3], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[4], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[5], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[6], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_offre.tableau[7]]
				let nboffre_hp = [this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_offre.tableau[0], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_offre.tableau[1], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_offre.tableau[2], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_offre.tableau[3], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_offre.tableau[4], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_offre.tableau[5], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_offre.tableau[6], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_offre.tableau[7]]
				let nbvente_pi = [this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[0], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[1], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[2], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[3], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[4], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[5], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[6], this.app.kpi_fil_volume.prix_trimestre_avec_stat.prix_vente.tableau[7]]
				let nbvente_hp = [this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_vente.tableau[0], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_vente.tableau[1], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_vente.tableau[2], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_vente.tableau[3], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_vente.tableau[4], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_vente.tableau[5], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_vente.tableau[6], this.app.kpi_fil_volume.prix_trimestre_hors_stat.prix_vente.tableau[7]]

				this.fil_chartOptions_prix_trim_pi.series[0].data = nboffre_pi
				this.fil_chartOptions_prix_trim_pi.series[1].data = nbvente_pi
				this.fil_chartOptions_prix_trim_pi.xAxis.categories= [this.app.kpi_fil_volume.prix_trimestre_hors_stat.intitule_colonne[0], this.app.kpi_fil_volume.prix_trimestre_hors_stat.intitule_colonne[1], this.app.kpi_fil_volume.prix_trimestre_hors_stat.intitule_colonne[2], this.app.kpi_fil_volume.prix_trimestre_hors_stat.intitule_colonne[3], this.app.kpi_fil_volume.prix_trimestre_hors_stat.intitule_colonne[4], this.app.kpi_fil_volume.prix_trimestre_hors_stat.intitule_colonne[5], this.app.kpi_fil_volume.prix_trimestre_hors_stat.intitule_colonne[6], this.app.kpi_fil_volume.prix_trimestre_hors_stat.intitule_colonne[7]]

				for (let j=0; j<8; j++) {
					if (min == 0 || min > nboffre_pi[j]) {
						min = nboffre_pi[j]
					}
					if (min == 0 || min > nboffre_hp[j]) {
						min = nboffre_hp[j]
					}
					if (min == 0 || min > nbvente_pi[j]) {
						min = nbvente_pi[j]
					}
					if (min == 0 || min > nbvente_hp[j]) {
						min = nbvente_hp[j]
					}

					if (max == 0 || max < nboffre_pi[j]) {
						max = nboffre_pi[j]
					}
					if (max == 0 || max < nboffre_hp[j]) {
						max = nboffre_hp[j]
					}
					if (max == 0 || max < nbvente_pi[j]) {
						max = nbvente_pi[j]
					}
					if (max == 0 || max < nbvente_hp[j]) {
						max = nbvente_hp[j]
					}
				}

				min = min - 100
				if (min < 0 ) {min = 0}
				max = max + 100
				this.fil_chartOptions_prix_trim_pi.yAxis.min = min
				this.fil_chartOptions_prix_trim_pi.yAxis.max = max




				this.fil_chartOptions_groff.series[0].data = [
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[0], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[1], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[2], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[3], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[4],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[5],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[6],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_plan.tableau[7]
				]
				this.fil_chartOptions_groff.series[1].data = [
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[0], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[1], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[2], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[3], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[4],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[5],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[6],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_chantier.tableau[7]
				]
				this.fil_chartOptions_groff.series[2].data = [
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[0], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[1], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[2], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[3], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[4],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[5],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[6],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.offre_livre.tableau[7]
				]
				this.fil_chartOptions_groff.xAxis.categories= [
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[0], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[1], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[2], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[3], 
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[4],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[5],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[6],
					this.app.kpi_fil_volume.volume_trimestre_ventilation_offre.intitule_colonne[7]
				]

				this.chartOptions_mois.series[0].data = [
					this.app.kpi_fil_volume.volume_trimestre.offre.tableau[0], 
					this.app.kpi_fil_volume.volume_trimestre.offre.tableau[1], 
					this.app.kpi_fil_volume.volume_trimestre.offre.tableau[2], 
					this.app.kpi_fil_volume.volume_trimestre.offre.tableau[3], 
					this.app.kpi_fil_volume.volume_trimestre.offre.tableau[4],
					this.app.kpi_fil_volume.volume_trimestre.offre.tableau[5],
					this.app.kpi_fil_volume.volume_trimestre.offre.tableau[6],
					this.app.kpi_fil_volume.volume_trimestre.offre.tableau[7]
				]
				this.chartOptions_mois.series[1].data = [
					this.app.kpi_fil_volume.volume_trimestre.vente.tableau[0], 
					this.app.kpi_fil_volume.volume_trimestre.vente.tableau[1], 
					this.app.kpi_fil_volume.volume_trimestre.vente.tableau[2], 
					this.app.kpi_fil_volume.volume_trimestre.vente.tableau[3], 
					this.app.kpi_fil_volume.volume_trimestre.vente.tableau[4],
					this.app.kpi_fil_volume.volume_trimestre.vente.tableau[5],
					this.app.kpi_fil_volume.volume_trimestre.vente.tableau[6],
					this.app.kpi_fil_volume.volume_trimestre.vente.tableau[7]
				]
				this.chartOptions_mois.series[2].data = [
					this.app.kpi_fil_volume.volume_trimestre.mev.tableau[0], 
					this.app.kpi_fil_volume.volume_trimestre.mev.tableau[1], 
					this.app.kpi_fil_volume.volume_trimestre.mev.tableau[2], 
					this.app.kpi_fil_volume.volume_trimestre.mev.tableau[3], 
					this.app.kpi_fil_volume.volume_trimestre.mev.tableau[4],
					this.app.kpi_fil_volume.volume_trimestre.mev.tableau[5],
					this.app.kpi_fil_volume.volume_trimestre.mev.tableau[6],
					this.app.kpi_fil_volume.volume_trimestre.mev.tableau[7]
				]
				this.chartOptions_mois.xAxis.categories= [
					this.app.kpi_fil_volume.volume_trimestre.intitule_colonne[0], 
					this.app.kpi_fil_volume.volume_trimestre.intitule_colonne[1], 
					this.app.kpi_fil_volume.volume_trimestre.intitule_colonne[2], 
					this.app.kpi_fil_volume.volume_trimestre.intitule_colonne[3], 
					this.app.kpi_fil_volume.volume_trimestre.intitule_colonne[4],
					this.app.kpi_fil_volume.volume_trimestre.intitule_colonne[5],
					this.app.kpi_fil_volume.volume_trimestre.intitule_colonne[6],
					this.app.kpi_fil_volume.volume_trimestre.intitule_colonne[7]
				]
			}
		},

		percent: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
					return Math.round(n) + ' %'
			}
            return ""
        },

		enmois: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
					return Math.round(n) + ' mois'
			}
            return ""
        },
		
		enprix: function(str) {
			if (str) {
				let n = parseFloat(str)
                if (n)                
                    n = Math.round(n/10)*10         
					return n.toLocaleString('fr-FR', {style: "decimal", currency: "EUR", maximumFractionDigits : "0"}) + " €"
			}
            return ""
        },
		transformDate: function(dateString) {
			// Séparer le mois et l'année
			if (!dateString) return ""
			const [month, year] = dateString.split('/');

			// Liste des noms des mois en français
			const mois = ['jan.', 'fév.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'];

			// Convertir le mois en index et l'année en format 20XX
			const moisNom = mois[parseInt(month, 10) - 1];  // On soustrait 1 car les mois sont indexés de 0 à 11
			const fullYear = `20${year}`;

			return `${moisNom} ${fullYear}`;
		},

	},

	// eslint-disable-next-line
	components: { ImagePreview, ProgressBar, SpeedDial, highcharts: Chart, ColumnGroup, Row, Knob, Column, DataTable, Panel, Dialog, Button, InputText, Textarea, Checkbox, InputNumber, SwitchButtonDpe, SwitchButton, Slider }
}

</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.ff
	height calc(100% - 460px) !important

.head_filter
	width 60px;
	height 60px;
	position absolute
	left 0px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important

._filtre
	top 84px
	height calc(100% - 144px) !important
	width 100%

.box
	background #f0f0f0
	
.box-content
	display block
	padding 0 16px
.box-title
	padding 0 16px
	margin 0 0 4px 0
	font-family volte, sans-serif
	font-weight 600
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
._box-title2
	padding 0 8px
	margin 0 0 4px 0
	font-family volte, sans-serif
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
	font-family "Roboto", arial, sans-serif
.box-title3
	padding 0 8px
	margin 0 0 4px 0
	font-family volte, sans-serif
	color #333
	line-height 16px
	text-align left
	padding-bottom 2px
	font-family "Roboto", arial, sans-serif

.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure
	width 100% !important

.aide
	width 60px
	left 0px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		left 0px !important

.select-all-co
	position absolute
	right 16px
	top 28%
	width 24px
	height 24px
	border-radius radius
	border 2px solid #CCC
	display block
	cursor pointer
	display flex
	background-color #FFF !important
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		background-color palette0 !important
		.chk .icon
			color #FFF
			display block
	&:hover
		background-color palette0 !important
	input
		position absolute
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap


.select-all
	position absolute
	right 16px
	top 22px
	width 24px
	height 24px
	border-radius radius
	border 2px solid palette0
	display block
	cursor pointer
	display flex
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		background-color palette0 !important
		.chk .icon
			color #FFF
			display block
	&:hover
		border-color palette2
	input
		position absolute
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap

.pane-gisements-enter
	transform translateX(100%)
.pane-gisements-leave-to
	transform translateX(-100%)

.module
	width PaneLeftSize
	height 40px
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc

.loading_graph
	.infinite-loader
		position absolute
		left calc(50% - 32px)
		top calc(50% - 64px)
		.loader
			display inline-block
			background main_color
			border none
			border-radius 100%
			color #ccc
			.icon
				color #ccc
				display block
				animation rotate 1.5s infinite linear

.pane-gisements
	position absolute
	top headersize
	float left
	height calc(100% - 126px)
	width PaneLeftSize
	transition 0.5s easeOutQuart
	.infinite-loader
		position absolute
		left 16px
		top calc(50% - 64px)
		.loader
			display inline-block
			background main_color
			border none
			border-radius 100%
			color #fff
			.icon
				display block
				animation rotate 1.5s infinite linear
	.programs
		top 0px
		height 100%
		overflow hidden
		background #fff
		&.empty
			.programs-no-result
				display flex
		.programs-footer
			bottom 0px
			height 68px
			padding 16px
			font-weight 600
			border-top 1px solid #ccc
		.programs-header
			padding 16px
			width PaneLeftSize
			font-weight 600
			border-bottom 1px solid #ccc
			> p
				line-height 16px
			.select-all-save
				position absolute
				right 16px
				top 12px
				width 24px
				height 24px
				background #fff
				border 2px solid #ccc
				border-radius radius
				cursor pointer
				&:hover
					border-color blue
				&:active
					border-color blue
				&.chk
					background-color #999
					border 2px solid #fff
			.select-allA
				position absolute
				right 16px
				top 12px
				width 24px
				height 24px
				background #fff
				border 2px solid #ccc
				border-radius radius
				cursor pointer
				&:hover
					border-color blue
				&:active
					border-color blue
				&.chk
					background #fff url(../assets/img/checked.png) center center no-repeat
					background-size 14px 14px

		.programs-no-result
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			height 100%
			padding 0 24px
			display none
			flex-direction column
			justify-content center
			align-items center
			background #fff
			color #999
			font-family volte, sans-serif
			text-align center
			font-weight 600
			font-size 20px
			line-height 24px
			user-select none
			.icon
				color #aaa
			.text
				margin 24px 0 0 0
				p
					padding 0 0 8px 0
					&:last-child
						padding 0
			.button
				display inline-block
				height 48px
				margin 24px 0 0 0
				padding 0 24px
				border-radius radius
				cursor pointer
				background-color blue
				font-weight 500
				font-size 20px
				color #fff
				line-height @height
				&:hover
					background-color darken(blue, 10%)
				&:active
					background-color darken(blue, 20%)
					transform translateY(1px)
.nolive
	height calc(100% - 50px) !important

.commune
	background secondary
	padding 16px
	overflow hidden
	cursor pointer
	height 48px
	width 100%
	text-align center
	color white
	border-bottom 1px solid white

.buttonv
	border 0
	border-radius 0
	background primaryA400
	border-radius 18px
	font-family Montserrat, sans-serif
	font-style normal
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 215px
	height 36px
	cursor pointer
	&:hover
		background secondary
	> a
		color #FFFFFF !important


.unmodule_detail_titre
	width 100% !important

.programs-list
	.programs-list-content
		width 100% !important


.buttonv_inactive
	right 0
	border 0
	border-radius 0
	background #AAAAAA
	border-radius 18px
	font-style normal
	font-weight 500
	font-size 12px
	line-height 16px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 200px
	height 36px
.module
	width PaneLeftSize
	height 40px
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc
	cursor pointer

.panecontent
	border-right 1px solid #cccccc

.paneleft
	border 0 !important
	width 50% !important
	
.loading_graph
	width PaneLeftSize
	height 280px

.stat
	width 60px;
	height 60px;
	position absolute
	right 180px
	border-left 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 120px
	&.city
		right 120px
	&.citynotidf
		right 60px !important
.res
	position absolute
	width auto
	right 50px
	top 25px
	font-weight 500
	font-size 14px


.prg_pc
	width 50%
	line-height 60px
	text-align center
	vertical-align middle
	cursor pointer
	display block
	height 40px
	border-bottom 1px solid #ccc
	background-color #eee
	&.last
		border-left 1px solid #ccc
	&.active		
		background-color #fcfcfc
		border-bottom 4px solid #6C21F9
		font-weight 600
		&:hover
			border-bottom 4px solid #6C21F9
	&:hover
		border-bottom 4px solid #333

.prix
	text-align right
	font-weight 800
	
.column {
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
}

.dashboard-card {
	flex: 1;
	display: inline-block;
	width: 92%;
	padding: 20px;
	background-color: var(--white);
	border-radius: var(--radius-large);
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;	
}

.dashboard-card:not(:last-child) {
	margin-bottom: 1.5rem;
}
.dashboard-card.flex-chart {
	height: calc(50% - 0.75rem);
}

.dashboard-card .card-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.dashboard-card .card-head h3 {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.9rem;
	font-weight: 600;
	color: var(--dark-text);
}
.dashboard-card .revenue-stats {
	display: flex;
	margin-bottom: 10px;
}
.dashboard-card .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.dashboard-card .revenue-stats .revenue-stat span {
	display: block;
}
.dashboard-card .revenue-stats .revenue-stat span:first-child {
	color: var(--light-text);
	font-size: 0.9rem;
}
.dashboard-card .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.2rem;
	font-weight: 600;
}
.dashboard-card .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}
.dashboard-card .chart-media {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.dashboard-card .chart-media .meta {
	margin-right: 30px;
	align-items: center;
	font-family: var(--font), sans-serif;
}
.dashboard-card .chart-media .meta h4 {
	font-family: var(--font-alt), sans-serif;
	font-size: 1rem;
	font-weight: 600;
	color: var(--dark-text);
}
.dashboard-card .chart-media .meta span {
	display: block;
	color: var(--dark-text);
	font-size: 1.2rem;
	font-weight: 600;
	margin-bottom: 8px;
}
.dashboard-card .chart-media .meta p {
	font-size: 0.9rem;
}
.dashboard-card .chart-media .chart-container {
	min-width: 110px;
}
.dashboard-card .chart-group {
	display: flex;
}
.dashboard-card .chart-group .group {
	text-align: center;
	width: 33.3%;
}
.dashboard-card .chart-group .group .chart-container {
	margin-top: -5px;
}
.dashboard-card .chart-group .group span {
	display: block;
	margin-top: -25px;
	font-family: var(--font), sans-serif;
	color: var(--dark-text);
	font-weight: 600;
	font-size: 1.2rem;
}
.dashboard-card .chart-group .group p {
	font-size: 0.9rem;
}
	
.oir_but {
	position: absolute;
	width: 250px;
	height: 50px;
	bottom:10px;
	left:calc(50% - 125px);
	background: #F0E63C;
	border-radius: 77px;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 50px;
	align-items: center;
	text-align: center;
	letter-spacing: 0.01em;
	color: #000000;
	cursor: pointer;
}
		
:deep(.p-panel-content) {
	padding: 0px !important;
}

:deep(.p-panel-header) {
	border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
	border-bottom: 0px !important;
	border-right: 0px !important;
	border-left: 0px !important;
	background: #eee !important;
    font-weight: 700 !important;
	font-size: 0.9rem !important;
	padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
}

:deep(.p-datatable .p-column-header-content) {
    justify-content: center;
}

:deep(.p-datatable .p-datatable-thead>tr>th) {
    border-width: 1px 1px 1px 1px;
}

1px solid #dee2e6
@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
