<template>
	<div class="login">
		
		<div class="surface-ground px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center h-full">
			<div v-if="app.autolog" class="surface-card p-4 shadow-2 border-round w-full lg:w-4">
				<div class="text-center mb-5">
					<icon file="e-focus2" style="color:#9C27B0;width:200px;height:80px" />
					<div class="text-900 text-3xl font-medium mb-3">{{ $t('connexion_a_lapplication') }}</div>					
				</div>

				<div>
					<div style="text-align:center">
						<div alt="efocus">									
							<br>
							<br>
							<icon file="e-focus2" style="color:#ffffff;width:200px;height:80px" />
							<br>
							<br>
							<div class="titleefocus" style="text-align: center;color:#ffffff">
								<p>
									{{ $t('auto_login') }}
								</p>
							</div>
							<br>
							<br>
							<div>
								<div class="loader">
									<icon file="loader" :size="32" style="color:#9C27B0;width:200px;height:80px" />
								</div>
							</div>
							<br>
						</div>
					</div>
				</div>
			</div>

			<div v-else class="surface-card p-4 shadow-2 border-round w-full lg:w-4">
				<div class="text-center mb-5">
					<div class="text-900 text-3xl font-medium mb-3"><icon file="e-focus2" style="color:#9C27B0;width:200px;height:80px" /></div>					
					<div class="text-900 text-3xl font-medium mb-3">{{ $t('connexion_a_lapplication') }}</div>
					<span v-if="!cdchinurl" class="text-600 font-medium line-height-3" style="line-height: 18px !important;font-size: 11pt;">{{ $t('pas_de_compte_?') }}</span>
					<Button v-if="!cdchinurl" label="Créez le aujourd'hui!" class="p-button-link font-medium no-underline ml-2 text-blue-500 cursor-pointer" style="padding: 0px;    font-family: Roboto, arial, sans-serif;font-size: 11pt;" onclick='$("#zohohc-asap-web-launcherbox").click();' />
				</div>

				<div v-if="!cdchinurl">
					<label for="email" class="block text-900 font-medium mb-2">{{ $t('email') }}</label>
					<InputText id="email" type="text" class="w-full mb-3"  v-model="login.email" v-on:keyup="verifcdch()" @keyup.enter="$refs.password.$el.focus();"/>

					<div v-if="!cdch">
						<label for="password" class="block text-900 font-medium mb-2">{{ $t('mot_de_passe') }}</label>
						<Password ref="password" id="password" :feedback="false" class="w-full mb-3" v-model="login.password" toggleMask @keyup.enter="submit_login()"></Password>
						<div class="flex align-items-center justify-content-between mb-6">
							<div class="flex align-items-center">
							</div>
							<a href="https://utilisateurs.adequation.fr/reset-password-general?from=F7FC5BC0-6312-4696-AF6C-9A6E5E05D686" class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">{{ $t('mot_de_passe_oublie_?') }}</a>
						</div>
					</div>
					<div v-else class="text-center mb-5">
						<br><br>
						<div class="text-900 text-3xl font-medium mb-3"><img alt="cdc" src="../assets/img/logo_cdch_transparent_200.png" style="color:#9C27B0;width:200px;height:auto" /></div>
					</div>
					<div class="field-checkbox">
						<Checkbox id="binary" v-model="cgr" :binary="true" />
						<label for="binary">{{$t('J\'accepte les conditions générales d\'utilisation et la politique de traitement des données personnelles.')}}</label>&nbsp;<a target="_blank" href="https://www.myadequation.fr/EFOCUS/CGU_V1.html" class="font-medium no-underline text-blue-500 cursor-pointer" style="color:grey !important;text-decoration: underline !important;">{{ $t('en_savoir_plus.') }}</a>
					</div>

					<div v-if="!cdch">
						<div v-if="app.loading" style="width:100%; text-align:center;"><ProgressSpinner style="width:40px;height:40px;"/></div>
						<Button v-else-if="cgr" label="Connexion" icon="pi pi-user" class="w-full" @click="submit_login()"></Button>
						<Button v-else label="Connexion" icon="pi pi-user" class="w-full" @click="accept_cgr()"></Button>
					</div>
					<div v-else>
						<div v-if="app.loading" style="width:100%; text-align:center;"><ProgressSpinner style="width:40px;height:40px;"/></div>
						<Button v-else-if="cgr" label="Connexion" icon="pi pi-user" class="w-full" onclick="window.location.href='https://api-utilisateurs-sys.adequation.fr/api/Sso/GetSSOUrl/F7FC5BC0-6312-4696-AF6C-9A6E5E05D686'"></Button>
						<Button v-else label="Connexion" icon="pi pi-user" class="w-full" @click="accept_cgr()"></Button>
					</div>
				</div>
				<div v-else>
					<br>
					<div class="field-checkbox">
						<Checkbox id="binary" v-model="cgr" :binary="true" />
						<label for="binary">{{$t('J\'accepte les conditions générales d\'utilisation et la politique de traitement des données personnelles.')}}</label>&nbsp;<a target="_blank" href="https://www.myadequation.fr/EFOCUS/CGU_V1.html" class="font-medium no-underline text-blue-500 cursor-pointer" style="color:grey !important;text-decoration: underline !important;">{{ $t('en_savoir_plus.') }}</a>
					</div>
					<br>
					<div class="text-center mb-5">
						<div class="text-900 text-3xl font-medium mb-3"><img alt="cdc" src="../assets/img/logo_cdch_transparent_200.png" style="color:#9C27B0;width:200px;height:auto" /></div>
						<div style="display: flex;justify-content: center;align-items: center;margin-bottom:10px;color:black"><InputSwitch v-model="recette" />&nbsp;&nbsp;{{ $t('En Recette') }}</div>
					</div>
					<br>
					<div v-if="app.loading" style="width:100%; text-align:center;"><ProgressSpinner style="width:40px;height:40px;"/></div>
					<div v-else>
						<div v-if="cgr">
							<Button v-if="recette" label="Connexion" icon="pi pi-user" class="w-full" onclick="window.location.href='https://api-utilisateurs-recette.adequation.fr/api/Sso/GetSSOUrl/F7FC5BC0-6312-4696-AF6C-9A6E5E05D686'"></Button>
							<Button v-else label="Connexion" icon="pi pi-user" class="w-full" onclick="window.location.href='https://api-utilisateurs-sys.adequation.fr/api/Sso/GetSSOUrl/F7FC5BC0-6312-4696-AF6C-9A6E5E05D686'"></Button>
						</div>
						<div v-else>
							<Button label="Connexion" icon="pi pi-user" class="w-full" @click="accept_cgr()"></Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
// eslint-disable-next-line
import InputSwitch from 'primevue/inputswitch';

export default {
	name: 'Login',
	data: function() {
		return {
			cdchinurl: false,
			recette: false,
			cdch: false,
			form: 'login',
			login: { email: '', password: '' },
			forgot: { email: '' },
			nom:'',
			cgr: true,
			prenom:'',
			email:'',
			tel:'',
			comment:''
		}
	},
	computed: Object.assign(mapGetters([ 'app' ]), {

	}),
	methods: {
		verifcdch: function() {
			this.cdch = false
			let em = this.login.email;
			if (em.indexOf('@cdc')>0) {
				this.cdch = true
			}
		},
		change_form: function(form) {
			let that = this
			this.form = form
			setTimeout(function() {
				$(that.$refs[form]).find('input.input:first').focus()
			}, 300)
		},
		accept_cgr: function() {
			this.$toast.add({severity:'error', summary: 'Conditions générales d\'utilisation', detail:'Vous devez accepter les conditions générales d\'utilisation afin de pouvoir utiliser le service', life: 5000});
		},
		submit_login: function() {
			if (!this.app.loading) this.$store.dispatch('login', { email: this.login.email, password: this.login.password })
		},
		submit_loginA: function() {
			if (!this.app.loading) this.$store.dispatch('loginA', { email: this.login.email, password: this.login.password })
		},
		submit_signup: function() {
			this.$store.dispatch('sendmail', {subject:'demande de création de compte', mail : this.email, content: 'De ' + this.nom + ' ' + this.prenom + ' #!email : ' + this.email + ' #!telephone : ' + this.tel + '#!message :#!' + this.comment.replace('↵', '#!').replace(/\r|\n/g,"#!")})
			this.$store.dispatch('SendEvent', { action: "account_createAccount", opt_label: "demande de création de compte", opt_value: 0 })
			this.$store.dispatch('add_notification', { status: 'success', message: "Votre demande a bien été prise en compte, vous serez contacter prochainement" })
			this.change_form('login')
		},
		submit_forgot: function() {
			this.$store.dispatch('forgot_password', {email: this.forgot.email})
			this.$store.dispatch('add_notification', { status: 'success', message: "Votre demande a bien été prise en compte, vous allez recevoir un mail prochainement" })
			this.change_form('login')
		}
	},
	mounted: function() {
		let that = this
		that.cdchinurl = false
		let uri = window.location.href.split('?');
		if (uri.length == 2) {
			let vars = uri[1].split('&');
			let getVars = {};
			let tmp = '';
			vars.forEach(function(v){
				tmp = v.split('=');
				if(tmp.length == 2)
				getVars[tmp[0]] = tmp[1];
				if (tmp[0]=="client" && tmp[1]=="cdch") {
					that.cdchinurl = true
				}
			});
		}
	},
	components: { InputSwitch, InputText, Button, ProgressSpinner, Checkbox, Password}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'
@import '../assets/css/grid'

.loader
	display inline-block
	background main_color
	border none
	border-radius 100%
	color #6c21f9
	.icon
		display block
		animation rotate 1.5s infinite linear


.login
	height 100%
	background-size cover
	color #FFFFFF
	background #fAfAfA
	.logo
		position absolute
		left 0
		top 0
		transform-origin center center
		left 16px
		top 16px
		height 40px
		img
			height 100%
	.box
		position absolute
		background palette0
		border-radius 5px
		left 50%
		top 50%
		transform translate(-50%, -50%)
		perspective 1000px
		.form-login, .form-signup, .form-forgot
			left 0
			top 0
			height 580px
			transform-origin center center
			background light
			border-radius radius
			overflow hidden
			box-shadow 0 0 20px alpha(#000, 25%)
			transition 0.15s linear
			transform-style preserve-3d
			backface-visibility hidden
			&.cdch
				background #f01e1e
			&.flip-enter
				transform translate(-50%, -50%) rotateY(-90deg)
			&.flip-leave-to
				transform translate(-50%, -50%) rotateY(90deg)
			.form
				width 420px
				padding 32px
				height 580px
				.title
					display block
					margin 0 0 24px 0
					font-weight 500
					font-size 16px
					text-transform uppercase
					color #333
				.line
					margin 0 0 16px 0
					.label
						display block
						margin 0 0 2px 0
						color #666
						text-transform uppercase
						font-size 11px
						font-weight 500
					.input
						display block
						width 100%
						height 48px
						padding 0 16px
						background #fff
						border 2px solid darken(light, 10%)
						border-radius radius
						font inherit
						color #333
						outline none
						&:focus
							border-color primaryA400
					textarea.input
						height auto
						padding 16px
						line-height 16px
					.forgot
						margin 4px 0 0 0
						line-height 16px
						text-decoration none
						font-size 12px
						cursor pointer
						color #999
						&:hover
							color blue
				.buttons
					text-align center
					.required
						position absolute
						left 0
						bottom 0
						color #999
						font-size 12px
						font-weight 400
					.button
						border none
						height 48px
						min-width 64px
						padding 0 16px
						border-radius radius
						background blue
						border none
						cursor pointer
						font inherit
						font-weight 500
						text-transform uppercase
						font-size 12px
						color #fff
						&:hover
							background-color darken(blue, 10%)
						&:active
							background-color darken(blue, 20%)
							transform translateY(1px)
						&.left
							float left
						&.gray
							background-color darken(light, 25%)
							&:hover
								background-color darken(light, 30%)
							&:active
								background-color darken(light, 35%)
						.loader
							position absolute
							left 2px
							top -5px
							transform scale(0.50)
					.buttonv
						border 0
						padding 0 16px
						border-radius 0
						background primaryA400
						border-radius 18px
						font-family Montserrat, sans-serif
						font-style normal
						font-weight 500
						font-size 14px
						line-height 16px
						line-height 36px
						text-align center
						letter-spacing 0.75px
						text-transform uppercase
						color #FFFFFF
						width 215px
						height 36px
						cursor pointer
						&:hover
							background secondary
						> a
							color #FFFFFF !important
					.buttonC
						border 0
						padding 0 16px
						border-radius 0
						background #FFF
						border-radius 18px
						font-family Montserrat, sans-serif
						font-style normal
						font-weight 500
						font-size 14px
						line-height 16px
						line-height 36px
						text-align center
						letter-spacing 0.75px
						text-transform uppercase
						color #FFFFFF
						width 215px
						height 36px
						cursor pointer
						> a
							color #FFFFFF !important
					.cdch
						background url(../assets/img/logo_cdch_200.png) center center no-repeat, linear-gradient(#fff, #fff)
						background-size 100px
							
			.no-account
				height 48px
				padding 16px 0
				background darken(light, 10%)
				box-shadow inset 0 2px 4px alpha(#000, 15%)
				text-align center
				font-weight 500
				font-size 12px
				line-height 16px
				cursor pointer
				&:hover
					background-color darken(light, 15%)

		.form-signup
			.form
				width 480px				


.buttona
	text-align center
	vertical-align middle
	border 0
	border-radius 0
	background #FFFFFF
	border-radius 18px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight 500
	font-size 14px
	line-height 16px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color primaryA400
	border 1px solid primaryA400
	width 215px
	height 36px
	&:hover
		background secondary
		color white
.titleefocus
	text-align center
	line-height 16px
	text-decoration none
	font-size 14px
	color #999
	&.cdch
		color #fff
.com
	text-align left
	line-height 16px
	text-decoration none
	font-size 12px
	color #666

.logo_efocus
	background palette0
	border-radius 5px
a
	text-decoration none
	&:link
		text-decoration none		
	&:visited
		text-decoration none
		color none
	&:focus
		text-decoration none
	&:hover
		text-decoration none
	&:active
		text-decoration none


.field-checkbox
	line-height 24px
	height 24px
	text-align left
	color grey
	

@keyframes p-progress-spinner-color {
	100%,
	0% {
		stroke: #ffffff;
	}
	40% {
		stroke: #ffffff;
	}
	66% {
		stroke: #ffffff;
	}
	80%,
	90% {
		stroke: #ffffff;
	}
}

:deep(.p-password-input) {
	width: 100%
}

</style>
